@import 'common/scss/utils.scss';

.plus {
  width: 100%;
  @include flex(flex-start, initial);

  .plus-btn {
    @include flex(center, center);
    margin-right: 16px;
    width: 64px;
    height: 64px;

    background: $gray-100;
    border-radius: 8px;

    cursor: pointer;

    font-size: 36px;
    font-weight: 300;
    color: $surface-medium;
    padding-bottom: 6px;
  }

  .newColorChips {
    @include flex(initial, initial);
    flex-direction: row-reverse;
  }
}
