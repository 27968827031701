@import 'common/scss/utils.scss';

#root-terms-service-table {
  .control-field {
    h2 {
      a {
        color: $surface-disabled;
        &.active {
          color: inherit;
        }
      }

      a:last-child {
        margin-left: 28px;
      }
    }
  }

  .expand-icon {
    @include flex(center, center);
    width: 48px;
    height: 48px;
    cursor: pointer;

    &.expanded {
      &::before {
        background-position: -25px -101px;
      }
    }

    &::before {
      content: '';
      display: block;
      background: kyc-icon(-44px, -115px);
      width: 17px;
      height: 10px;
    }
  }
}
