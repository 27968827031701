@import 'common/scss/utils.scss';

#admin-reviewer-modal-modify-my {
  .content {
    p {
      text-align: center;
      font-size: 14px;
      line-height: 22px;

      span {
        color: $primary-80;
      }
    }
  }
}
