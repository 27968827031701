@import 'common/scss/utils.scss';

#kyc-sign-up-page {
  @include flex(initial, center);
  width: 100%;
  height: 100%;
  background-color: $surface-100;

  .sign-up-content {
    width: 420px;
    padding-top: 96px;

    i.logo {
      @include flex(center, center);
      flex-shrink: 0;
      width: 100%;

      &::before {
        content: '';
        display: block;
        width: 102px;
        height: 16px;
        background: kyc-icon(-8px, -519px);
      }
    }
    .step-field {
      .step-informaion {
        @include flex(center, space-between);
        margin: 40px 0;

        .title {
          font-size: 24px;
          line-height: 36px;
          font-weight: bold;
        }

        .steps {
          @include flex(center, initial);
          gap: 10px;

          i {
            text-align: center;
            vertical-align: center;
            font-style: normal;
            border-radius: 50%;
            width: 26px;
            height: 26px;
            color: $surface-disabled;
            background: $gray-100;

            &.active {
              color: $primary-100;
              background: rgb(235, 236, 255);
            }
          }
        }
      }
    }

    .success-field {
      .success-icon {
        @include flex(center, center);
        width: 100%;
        padding-top: 160px;

        i {
          background: url('/assets/img/kyc_big.svg') no-repeat -296px -59px;
          width: 235px;
          height: 181px;
          transform: translateX(-8%);
        }
      }

      .notice-text {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        margin-top: 80px;
      }

      .description {
        margin-top: 8px;
        text-align: center;
      }

      .done {
        width: 100%;
        line-height: 60px;
        text-align: center;
        background-color: $primary-100;
        color: $surface-100;
        border-radius: 8px;
        margin-top: 72px;
      }
    }
  }
}
