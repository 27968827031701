@import 'common/scss/utils.scss';

#root-login {
  @include flex(initial, center);
  position: relative;
  height: calc(100% - 48px);
  min-height: 800px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 64%;
    left: 0;
    top: 0;
    z-index: 0;
    background: url('/assets/root-bg.svg') repeat-x;
  }

  #root-login-form-field {
    position: absolute;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    z-index: 1;

    .signin-title {
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 32px;
      color: $surface-100;

      i {
        background: kyc-icon(7px, -418px);
        width: 127px;
        height: 19px;
        scale: 2;
        filter: invert(1);
      }
    }

    section.form-section {
      width: 400px;
      padding: 40px;
      max-height: 100%;
      overflow: auto;
      background-color: $surface-100;
      border-radius: 8px;
      box-shadow: 0px 0px 8px rgb(95 101 255 / 10%), 0px 2px 8px 4px rgb(95 101 255 / 5%);
      .validator__form {
        .login-text {
          font-weight: 700;
          font-size: 24px;
          text-align: center;
          color: $primary-80;
          margin-bottom: 40px;
        }

        .jack__validator__error__message {
          /* Error / 10% */

          background: $error-10;
          border-radius: 8px;
          padding: 14px 16px !important;
        }

        .jack__validator__button {
          background-color: $primary-100;
          margin-top: 40px;

          &.disabled {
            background-color: $primary-20;
            color: $surface-100;
          }
        }
      }
    }
  }
}
