@import 'common/scss/utils.scss';

body {
  .ubt__input {
    width: 100%;
    .ubt__input__label {
      margin-bottom: 8px;
    }

    &.disabled {
      .ubt__input__field {
        input {
          background-color: #f6f6f6;
        }
      }
    }

    &.error {
      .ubt__input__field {
        input {
          border: 1px solid #ff5974 !important;
        }
      }
    }

    .ubt__input__field {
      width: 100%;
      height: 56px;
      input[type='password'] {
        padding-right: 60px !important;
      }
      input {
        border: 1px solid $gray-300;
        padding: 0 24px;
        border-radius: 8px;
        &::placeholder {
          font-size: 16px;
          color: $gray-300;
        }
        &:focus {
          border-color: $primary-80;
          box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);
        }
      }
      button.ubt__input__lock__icon {
        right: 24px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
