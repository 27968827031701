@import 'common/scss/utils.scss';

#term-policy {
  padding-top: 40px;

  .term-content {
    .term-title {
      @include flex(center, initial);
      margin-top: 20px;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;

      .jack__button {
        width: 117px;
        height: 36px;
        margin-left: auto;
        background-color: $surface-100;
        border: 1px solid $gray-200;

        & > div {
          font-size: 14px;
          font-weight: 500;
          color: $primary-80;
        }
      }
    }

    .inner-html {
      padding: 16px;
      width: 100%;
      max-height: 320px;
      border: 1px solid $gray-200;
      overflow: auto;
      margin-bottom: 32px;
      font-size: 14px;
    }

    .html-textarea {
      background-color: $surface-100;
      border-radius: 0;
      border: 1px solid $gray-200 !important;

      textarea {
        color: $surface-medium;
        background-color: $surface-100;
      }
    }
  }
}
