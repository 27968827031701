* {
  margin: 0;
  padding: 0;
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  box-sizing: border-box;
}

form {
  display: block;
}

@mixin no-outline {
  &,
  &:active,
  &:focus {
    outline: none;
  }
}

a {
  @include no-outline;
  display: block;
  text-decoration: none;
}

i {
  display: inline-block;
}

button {
  @include no-outline;
  border: none;
  cursor: pointer;
  background-color: initial;
  line-height: 0;
}

h4,
h3 {
  letter-spacing: 0.5px;
}

ul {
  list-style-type: none;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: #c5c6ca;
  border-radius: 25px 25px;
}
::-webkit-scrollbar-thumb:hover {
  background: #c5c6ca;
}

#root {
  width: 100vw;
  height: 100vh;
  color: #363636;
  overflow-x: hidden;
  overflow-y: hidden;
}
