@import 'common/scss/utils.scss';

.admin-page-manage-subscribe-info {
  padding: 24px 32px;
  background: $surface-100;
  // box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);

  .modules,
  .manager,
  .vas,
  .custom {
    @include flex(top, initial);
    .title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $surface-high;
      margin-right: 32px;
    }
    .info {
      font-size: 1rem;
      line-height: 1.5rem;
      span:first-child {
        color: $surface-high;
      }
      span.disabled {
        color: $surface-medium;
      }
    }
    &.manager {
      .info {
        span:first-child {
          margin-right: 10px;
        }
      }
    }
    &.vas {
      .info {
        span:first-child {
          display: inline-block;
          min-width: 78px;
          margin-right: 16px;
        }
      }
    }
  }
  .info-wrapper {
    @include flex(top, initial);
    & > div {
      width: 33%;
    }
  }
  hr {
    margin: 24px 0px;
    height: 1px;
    border: none;
    background: $gray-200;
  }
}
