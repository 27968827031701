@import 'common/scss/utils.scss';

.jack__input__container {
  &.value {
    .input__pw__lock__icon {
      &::before {
        background-position: -244.5px -195.5px !important;
        width: 22px !important;
        height: 19px !important;
      }

      &.open {
        &::before {
          background-position: -245px -222px !important;
          width: 22px !important;
          height: 17px !important;
        }
      }
    }
  }

  .input__pw__lock__icon {
    width: auto !important;
    height: auto !important;
    right: 24px !important;
    top: 30px !important;
    &::before {
      background: url('/assets/img/kyc_icons.svg') no-repeat -215px -196px !important;
      width: 22px !important;
      height: 19px !important;
    }

    &.open {
      &::before {
        background-position: -216px -222px !important;
        width: 22px !important;
        height: 16px !important;
      }
    }
  }

  &.readonly,
  &.disabled {
    .jack__input {
      &:focus {
        border-color: $gray-300 !important;
      }
    }
  }
}

// basic class
.jack__input__container.basic {
  input[type='password'] {
    padding-right: 60px !important;
  }

  input.jack__input {
    height: 56px;
    padding: 0 24px;
    border-radius: 8px;
    border: 1px solid $gray-300;
    font-size: 16px;

    &::placeholder {
      color: $gray-300;
    }

    &:focus {
      border-color: $primary-80;
      box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);
    }
  }
}

// // error-text
// .jack__input__container {
//   &.error-text-only {
//     .jack__validator__error__message {
//       background-color: transparent !important;
//       padding: 0 !important;
//     }
//   }

//   &.success-text-only {
//     .jack__validator__error__message {
//       background-color: transparent !important;
//       padding: 0 !important;
//       background: $primary-100;
//     }
//   }
// }
