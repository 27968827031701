@import 'common/scss/utils.scss';

#admin-settings-table {
  .jack__no__data {
    .jack__no__data__text {
      &::before {
        content: '설정값 변경 기록이 없습니다.';
      }
    }
  }

  .level_after {
    color: $primary-100;
    font-weight: 500;
  }
}
