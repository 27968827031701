@import 'common/scss/utils.scss';

#modal-root-ra-item-nations-setting {
  @mixin h4 {
    h4 {
      font-size: 14px;
      font-weight: 400;
      color: $surface-medium;
    }
  }
  .root__nations__controller {
    @include flex(center, space-between);
    gap: 104px;

    .searchbox__filter {
      @include h4;
      flex: 1;

      h4 {
        font-weight: 500;
        color: $surface-high;
      }

      .jack__search__form__field {
        margin-top: 8px;

        input {
          @include border($surface-disabled);
        }
        button {
          margin-right: 8px;
        }
      }
    }

    .score {
      @include h4;
      flex: 1;

      h4 {
        font-weight: 500;
        color: $surface-high;
      }

      .jack__input__container {
        margin-top: 8px;
      }
    }
  }

  .nations__container {
    @include h4;
    margin-top: 16px;
    position: relative;
    @include flex(initial, initial);
    // flex-direction: column;
    flex-direction: row; // 국가목록 가로모드
    gap: 16px;

    h4 {
      font-weight: 500;
      color: $surface-high;
    }
    .nations__table {
      flex: 1; // 국가목록 가로모드

      .head {
        // border-bottom: 1px solid $gray-200;

        h4 {
          display: inline-block;
          cursor: pointer;

          &.sorted {
            color: $primary-80;
          }
        }

        & > div {
          font-size: 14px;
          padding: 5px 16px 5px 5px;
          margin-top: 8px;
          @include flex(center, space-between);
          border: 1px solid $gray-200;
          border-bottom: 0;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          span {
            cursor: pointer;

            &.disabled {
              color: $surface-disabled;
              cursor: default;
            }
          }
        }

        i.sort__icon {
          width: 8px;
          height: 12px;
          background: kyc-icon(-77px, -167px);
          vertical-align: middle;
          margin-left: 5px;

          &.asc {
            background: kyc-icon(-77px, -179px);
          }
          &.desc {
            background: kyc-icon(-77px, -189px);
          }
        }
      }
      .content {
        min-height: 205px;
        max-height: 205px;
        overflow: auto;
        border: 1px solid $gray-200;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        position: relative;

        & > div:not(.nation) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          color: $surface-medium;
        }

        .nation {
          @include flex(center, space-between);
          padding: 5px;
          border-bottom: 1px solid $gray-200;

          &.checked {
            background-color: $primary-5;
          }

          & > span {
            @include flex(center, initial);
            gap: 8px;

            &:last-of-type {
              span {
                @include flex(initial, initial);
                font-size: 12px;
                color: $surface-medium;
                border: 1px solid $surface-disabled;
                border-radius: 100px;
                padding: 2px 8px;
              }
            }
          }
        }
      }
    }

    .control {
      @include flex(center, center);
      gap: 16px;
      flex-direction: column; // 국가목록 가로모드

      .jack__button {
        @include border($gray-200);
        padding: 8px 12px;
        color: $primary-80;
        background-color: $surface-100;

        &.append {
          i {
            // rotate: 90deg;
            rotate: 0deg; // 국가목록 가로모드
          }
        }
        &.remove {
          .jack__button__context {
            flex-direction: row-reverse; // 국가목록 가로모드
          }
          i {
            // rotate: 270deg;
            rotate: 180deg; // 국가목록 가로모드
          }
        }

        &.disabled {
          color: $surface-disabled;
          opacity: 0.8;
          filter: grayscale(1);
        }

        .jack__button__context {
          gap: 8px;
        }

        i {
          width: 16px;
          height: 16px;
          background: kyc-icon(-96px, -117px);
        }
      }
    }
  }

  .info {
    font-size: 14px;
    color: $surface-medium;
    padding: 8px 0px;
    text-align: right;
  }

  .modal__footer {
    @include flex(center, center);

    button {
      flex: unset;
      &.footer__cancel {
        width: 162px;
      }
      &.footer__submit {
        width: 243px;
      }
    }
  }
}
