@import './utils.scss';

.jack__checkbox__container {
  padding: 3px;
  display: flex;
  align-items: center;
  font-style: normal;
  cursor: pointer;
  user-select: none;

  &.checked {
    .jack__checkbox {
      background-color: #003288;
      border: 1px solid #003288;
    }
  }

  &.indeter {
    .jack__checkbox {
      background-color: #003288;
      border: 1px solid #003288;
    }
  }

  &.disabled {
    cursor: initial;
    .jack__checkbox {
      background-color: #f5f5f5;
      border: 1px solid #e6e6e6;
    }

    .jack__checkbox__label {
      opacity: 0.3;
    }
  }

  .jack__checkbox {
    width: 12px;
    height: 12px;
    position: relative;
    border-radius: 2px;
    box-sizing: border-box;
    flex-shrink: 0;
    border: 1px solid #a7a7a7;
    background-color: #fff;

    .ssr-checkbox-check-icon {
      width: 100%;
      height: 100%;
      background: url('../assets/check.svg') no-repeat center;
    }

    .ssr-checkbox-indeterminate-icon {
      width: 100%;
      height: 100%;
      background: url('../assets/indeter.svg') no-repeat center;
    }
  }

  .jack__checkbox__label {
    display: flex;
    align-items: center;
    margin-left: 8px;
    transition: color 0.1s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    color: inherit;
    font-size: inherit;
  }
}
