@import 'common/scss/utils.scss';

article.root-page-client-expand-functions {
  @include flex(baseline, initial);
  gap: 16px;

  h5 {
    font-weight: 500;
    font-size: 16px;
    width: 150px;
  }
  div.setting-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(10px, auto);
    align-items: start;
    gap: 16px;
    flex: 1;

    div.setting-item {
      border: 1px solid $gray-200;
      border-radius: 8px;

      &:first-of-type {
        grid-row: 1 / 5;
      }

      .jack__switch__container {
        &:is(.switch-box .jack__switch__container) {
          scale: 1.1;
        }
        &:is(.cate-title .jack__switch__container) {
          scale: 0.9;
        }
      }

      .switch-box {
        @include flex(center, space-between);
        width: 100%;
        height: 40px;
        padding: 0 16px;
        background-color: $gray-100;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid $gray-200;
        border-radius: 8px 8px 0 0;

        &:last-of-type {
          border-radius: 8px;
          border-bottom: 0;
        }
        &.active {
          background-color: $primary-5;
          color: $primary-100;
        }
        &.inactive {
          color: $surface-disabled;
        }
      }

      .setting-item-content {
        @include flex(initial, initial);
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        padding-left: 0;

        .jack__radio__group {
          flex-direction: row;

          .jack__radio__item {
            flex: 1;
            padding: 16px;
            box-shadow: 0px 0px 0px 1px inset $gray-200;

            &.checked {
              box-shadow: 0px 0px 0px 2px inset $primary-80;
              font-weight: 700;
            }

            &:first-of-type {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            &:last-of-type {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }

            i.jack__radio__box {
              @include flex(center, center);
              text-align: center;

              .radio-box {
                display: none;
              }
              .radio-box-text {
                margin-left: 0;
              }
            }
          }
        }

        .category {
          @include flex(initial, initial);
          flex-direction: column;
          gap: 16px;
          padding-left: 16px;

          .category {
            .cate-title {
              position: relative;
              &::before {
                content: '';
                display: block;
                position: absolute;
                left: -10px;
                top: 50%;
                width: 2px;
                height: 2px;
                border: 2px solid $surface-high;
                border-radius: 50%;
              }
            }
          }

          .cate-title {
            @include flex(center, space-between);
            position: relative;
            & > div {
              @include flex(center, initial);
            }

            .tooltip {
              @include flex(center, initial);
              position: relative;
              cursor: pointer;
              margin-left: 6px;
              background: url('/assets/img/kyc_icons.svg') no-repeat -196px -318px;
              width: 16px;
              height: 16px;
            }
          }

          .auth-code-message {
            margin-top: 8px;

            .jack__input {
              width: 148px;
              height: 40px;
              padding: 8px 16px;
              border: 1px solid $gray-300;
              border-radius: 8px;
              color: $surface-medium;

              font-size: 0.875rem;
              line-height: 1.5rem;
              &::placeholder {
                font-size: 0.875rem;
                line-height: 1.5rem;
                color: $surface-disabled;
              }
            }
          }
        }

        .border {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            top: -8px;
            border: none;
            border-top: 1px solid $gray-200;
            padding-right: 16px;
          }
        }
      }
    }
  }
}

#root-page-client-expand-popover.description {
  z-index: 10;
  background: url('/assets/img/kyc_icons.svg') no-repeat -455px -308px;
  width: 234px;
  height: 80px;

  position: absolute;
  left: 0%;
  top: 100%;
  transform: translateX(-9%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 25px 25px 10px 25px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
