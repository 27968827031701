@import 'common/scss/utils.scss';

#root-client-linked-account-modal {
  text-align: center;
  .modal__content {
    padding: 0px 82px;
    .active-modal-info {
      .img {
        background: url('/assets/img/kyc_icons.svg') no-repeat -662px -94px;
        width: 63px;
        height: 63px;
        margin: 0 auto;
        margin-bottom: 24px;
      }
      .info {
        @include flex(center, center);

        .date {
          font-size: 0.8125rem;
          line-height: 1.25rem;
          color: $surface-medium;
        }
        .id {
          font-size: 1rem;
          line-height: 1.5rem;
          color: $surface-high;
        }
      }
      hr {
        margin: 16px auto;
        background: $gray-300;
        height: 1px;
        border: none;
      }
      .detail {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $surface-high;
      }
    }
  }
}
