@import 'common/scss/utils.scss';

.admin-page-reviewer-table-active {
  .approval {
    @include flex(center, initial);
    font-size: 16px;
    margin-left: 16%;

    .approve {
      color: $primary-100;
      cursor: pointer;
    }

    .vertical-line {
      width: 1px;
      height: 16px;
      background-color: $gray-300;
      margin: 0 16px;
    }

    .reject {
      color: $error-100;
      cursor: pointer;
    }
  }
}
