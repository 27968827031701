@import 'common/scss/utils.scss';

#identification__modal__wrapper {
  text-align: center;
  .modal__content__form {
    .status-container {
      @include flex(center, center);

      .status {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $surface-high;
        &.ok {
          color: $primary-100;
          font-weight: 500;
        }
      }
      .icon {
        width: 16px;
        height: 12px;
        margin: 5px;
        background: url('/assets/img/kyc_icons.svg') no-repeat -64px -47px;
      }
    }
    hr {
      background: $gray-300;
      margin: 16px 40px;
      border: none;
      height: 1px;
    }
  }
}
