@import 'common/scss/utils.scss';

#root-eddra-nation-label {
  margin-top: 16px;
  @include flex(initial, initial);
  flex-direction: column;
  gap: 16px;

  & > article {
    padding: 24px 16px;
    background-color: $surface-100;
    box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);
  }
}
