@import 'common/scss/utils.scss';

.customfield-config-controller {
  margin: 24px 0;
  @include flex(center, space-between);
  padding: 16px 24px;
  background-color: $surface-100;
  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);

  h4 {
    font-weight: 500;
    font-size: 16px;
    color: $surface-high;
  }

  .controller {
    @include flex(center, space-between);
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 12px 16px;
    padding-left: 24px;
    width: 100%;
    background-color: $gray-100;

    &.active {
      background-color: $primary-10;
    }
    &.edit {
      background-color: $surface-100;
    }

    & > div:first-of-type {
      @include flex(center, initial);
      gap: 16px;
      color: $surface-medium;
      font-size: 14px;
    }
  }

  .button-group {
    margin-left: auto;
    width: 160px;
    @include flex(center, flex-end);
    gap: 8px;

    button {
      display: block;
      padding: 8px 12px;
      border: 1px solid $gray-200;
      border-radius: 8px;

      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;

      color: $surface-medium;

      &:last-child {
        color: $primary-80;
      }

      &[disabled] {
        background-color: $surface-100;
        color: $surface-disabled;
      }
    }
  }

  .title-info {
    background: kyc-icon(-196px, -318px);
    width: 16px;
    height: 16px;
    margin-top: 4px;
    cursor: pointer;
    position: relative;
  }
}

#admin-manage-customfield-setting {
  position: relative;
  .header {
    @include flex(center, initial);
    font-size: 1rem;
    line-height: 1.5rem;
    color: $surface-high;

    .title {
      margin-left: 8px;
      font-weight: 500;

      margin-right: 32px;
    }
    .custom-field--title {
      margin-right: 6px;
    }

    .subtitle {
      @include flex(center, initial);
    }

    .content {
      margin-left: 147px;
    }

    .button-group {
      margin-left: auto;
      @include flex(center, initial);
      gap: 8px;

      button {
        display: block;
        padding: 8px 12px;
        border: 1px solid $gray-200;
        border-radius: 8px;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;

        color: $surface-medium;

        &:last-child {
          color: $primary-80;
        }

        &[disabled] {
          color: $surface-disabled;
        }

        &.jack__validator__button {
          background-color: $surface-100;
        }
      }
    }
  }

  .customfield-wrapper {
    /* height: 0;
    overflow: hidden;

    &.active {
      overflow: initial;
      margin-top: 17px;
      height: 100%;
    } */
    // margin-top: 48px;
    // overflow: hidden;
    min-height: 297px;
    // transition: min-height 0.3s ease;

    &.active {
      // overflow: scroll; // need remove
      min-height: auto;
    }
  }
}

#admin-manage-customfield-popover-content.description {
  background: kyc-icon(-384px, -546px);
  width: 316px;
  user-select: none;
  height: 73px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-3%, -35%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 18px 24px 18.5px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
