@import 'common/scss/utils.scss';

#admin-manage-ra-realscore-content {
  margin-top: 24px;
  h3 {
    font-size: 20px;
    font-weight: 500;
    color: $surface-medium;
  }
}
