@import 'common/scss/utils.scss';

.jack__switch__container {
  width: 32px;
  height: 16px;

  &.on {
    background-color: $primary-100;
    .switch__button {
      left: 18px;
    }
  }

  &.readonly.on {
    background-color: #afb2ff !important;
  }

  .switch__text {
    display: none;
  }

  .switch__button {
    width: 12px;
    height: 12px;
  }
}
