@import 'common/scss/utils.scss';

#delet_hmac__modal__wrapper {
  text-align: center;
  .modal__footer {
    .footer__submit {
      background: $error-100;
    }
  }
}
