@import 'common/scss/utils.scss';

#card-result-fail-root {
  @include flex(center, center);
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;

  .card-result-fail-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 150px;
    margin-bottom: 56px;

    i.error-icon {
      background: url('/assets/img/kyc_icons.svg') no-repeat -473px -144px;
      width: 104px;
      height: 120px;
      margin-bottom: 40px;
      margin-left: 10px;
    }

    .error-message {
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      color: $primary-100;
      margin-bottom: 24px;
    }

    .description {
      font-weight: normal;
      text-align: center;
      font-size: 16px;
      line-height: 26px;
      color: $surface-high;
      margin-bottom: 16px;
    }

    .contact {
      font-weight: normal;
      text-align: center;
      font-size: 14px;
      line-height: 21px;
      color: $surface-medium;

      .mail {
        color: $surface-medium;
      }
    }
  }

  // .button-container {
  //   cursor: pointer;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-weight: 500;
  //   font-size: 16px;
  //   width: calc(100% - 60px);
  //   color: white;
  //   background-color: $primary-100;
  //   border-radius: 8px;
  //   height: 60px;
  //   margin: 0 30px 48px;
  // }
}
