@import 'common/scss/utils.scss';

#subscribe-management-setting {
  margin-top: 40px;
  .section-title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    color: $surface-medium;
  }
  h2:not(:first-child) {
    margin-top: 40px;
  }
  section {
    padding: 18px 32px;
    background: $surface-100;
    .header {
      // display: block;
      height: 38px;
    }
  }
  .row {
    margin-top: 16px;
    @include flex(stretch, initial);
    gap: 16px;
    section {
      flex: 1;
    }
  }
}
