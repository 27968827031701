@import 'common/scss/utils.scss';

#root-client-table {
  .active-column {
    @include flex(center, center);
    width: 108px;
    height: 32px;
    background-color: $gray-100;
    color: $surface-disabled;
    border-radius: 8px;

    &.active {
      color: $primary-80;
      background-color: $primary-5;
    }
  }

  .expand-icon {
    @include flex(center, center);
    width: 48px;
    height: 48px;
    cursor: pointer;

    &.expanded {
      &::before {
        background-position: -25px -101px;
      }
    }

    &::before {
      content: '';
      display: block;
      background: kyc-icon(-44px, -115px);
      width: 17px;
      height: 10px;
    }
  }
}
