@import 'common/scss/utils.scss';

.edd-setting-country-risk-detail {
  @include flex(initial, initial);
  flex-direction: column;
  gap: 8px;

  .title {
    @include flex(center, initial);
    height: 38px;
    color: $surface-high;
    margin-bottom: 0px;
    transition: margin-bottom 0.3s ease;

    h4 {
      font-weight: 700;
      margin-left: 24px;
    }
    .score {
      @include flex(center, flex-end);
      gap: 24px;
      flex: 1;
      margin-right: 24px;

      .edit {
        visibility: hidden;
      }

      div {
        @include flex(center, initial);
        gap: 32px;

        &:first-child {
          span {
            color: $surface-high;
          }
        }

        span:first-child {
          color: $surface-medium;
          font-size: 14px;
          font-weight: 400;
        }
        span:last-child {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    i {
      width: 18px;
      height: 18px;
      background: kyc-icon(-24px, -110px);
      transform: rotate(90deg);
      cursor: pointer;
    }

    .control {
      @include flex(center, center);
      width: 120px;
      flex: unset;

      .button-group {
        @include flex(center, flex-end);
        gap: 8px;
        margin-left: auto;

        button {
          padding: 8px 12px;
          @include border($gray-200);
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.25rem;
          background-color: $surface-100;
          color: $surface-medium;

          &:last-child {
            color: $primary-80;
          }

          p {
            margin-left: auto;
            margin-right: 96px;
            font-weight: 400;
          }

          &[disabled] {
            color: $surface-disabled;
          }
        }
      }
    }
  }

  .nation-risk-type {
    .info {
      @include flex(center, initial);
      gap: 16px;
      padding: 16px;
      border: 1px solid $gray-200;
      border-radius: 8px;
      color: $surface-high;

      span {
        font-size: 14px;
      }
    }

    .radio__group {
      @include flex(initial, space-between);
      flex-direction: row;
      gap: 16px;

      .radio__item {
        flex: 1;
        padding: 16px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        color: $surface-high;

        i {
          width: 20px;
          height: 20px;
          background: kyc-icon(-143.5px, -223px);
        }
        &.checked {
          border: 1px solid $primary-100;
          i {
            background: kyc-icon(-120px, -223px);
          }
        }

        .jack__button__context {
          @include flex(initial, initial);
          flex-direction: column;
          & > * {
            @include flex(center, initial);
          }
          div {
            font-size: 16px;
            gap: 8px;
          }

          p {
            margin-top: 16px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
    .jack__radio__group {
      @include flex(initial, space-between);
      flex-direction: row;
      gap: 16px;

      .jack__radio__item {
        flex: 1;
        padding: 16px;
        border: 1px solid $gray-200;
        border-radius: 8px;

        &.checked {
          border: 1px solid $primary-100;
        }

        p {
          margin-top: 8px;
          font-size: 14px;
        }
      }

      .radio-box-text {
        font-weight: 700;
      }
    }
  }

  .item-container {
    margin-top: 16px;
  }

  .item__subtree {
    @include flex(center, space-between);
    padding: 12px 24px;
    height: auto;

    &.header {
      span {
        flex: unset;
      }
    }
    &.footer {
      padding: 6px 8px;
    }

    div.info {
      @include flex(initial, initial);
      flex-direction: column;

      .name {
        color: $surface-high;
      }

      .warn__labels {
        @include flex(initial, initial);
        gap: 8px;

        span {
          @include flex(initial, initial);
          flex: unset;
          font-size: 12px;
          color: $surface-medium;
          border: 1px solid $surface-disabled;
          border-radius: 100px;
          padding: 4px 8px;
          margin-top: 8px;
        }
      }
    }

    span.score {
      @include flex(center, initial);
      flex: unset;
      color: $surface-high;
      font-weight: 500;
    }
  }

  .hide {
    visibility: hidden !important;
  }
  .error {
    color: $error-100;
  }
}
