.jack__checkbox__container {
  &.readonly.checked {
    .jack__checkbox {
      background-position: -58px -316px;
    }
  }

  .jack__checkbox {
    border: none !important;
    width: 17px;
    height: 17px;
    background: url('/assets/img/kyc_icons.svg') no-repeat -84px -316px;
    background-color: transparent !important;
  }

  &.checked {
    .jack__checkbox {
      background-position: -32px -316px;
    }
  }
  &.disabled.checked {
    .jack__checkbox {
      background-position: -7px -316px !important;
    }
  }

  &.circular {
    .jack__checkbox {
      width: 21px;
      height: 21px;
      background-position: -141px -315px;
    }

    &.checked {
      .jack__checkbox {
        background-position: -110px -315px;
      }
    }
  }
}
