@import 'common/scss/utils.scss';

#admin-reviewer-table {
  .jack_table__row {
    .jack_table__td {
      &:last-child {
        .jack_table__td__context {
          .text-node {
            width: 100%;
          }
        }
      }
    }
    .jack_table__tr {
      .admin-page-reviewer-table-active {
        @include flex(center, initial);
      }
    }
  }

  .jack__no__data {
    .content__wrapper {
      .jack__no__data__text {
        &::before {
          content: '등록된 심사자 항목이 없습니다.';
        }
      }
    }
  }
}
