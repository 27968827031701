// 모달 내 select box css
.jack__select {
  width: 100%;
  height: 56px;
  .jack__select__button {
    border-radius: 8px !important;
    font-size: 16px;

    &.active {
      border-color: $primary-80 !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-bottom: 1px solid $surface-100 !important;
    }
  }

  .jack__select__options {
    border-radius: 8px !important;
    box-shadow: none !important;
    border: 1px solid $gray-200;
    top: 56px !important;
    border-color: $primary-80;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: none;

    .jack__select__item {
      font-size: 16px;
      height: 56px;

      &.active {
        font-weight: 400;
        color: $active-100;
      }

      &:hover {
        background: $primary-10 !important;
        color: $primary-80;
      }
    }
  }
}
