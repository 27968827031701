@import 'common/scss/utils.scss';

article.root-page-client-expand-addons {
  @include flex(baseline, initial);
  gap: 16px;

  .extras-title {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $surface-high;
  }

  p {
    line-height: 22px;
    margin-bottom: 4px;
  }

  h5 {
    font-weight: 500;
    font-size: 16px;
    width: 150px;
  }

  .addons-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(186px, auto));
    gap: 16px;
    flex: 1;

    .category {
      &.reuse {
        @include flex(center, initial);

        .jack__input__container {
          width: 54px;
          input {
            text-align: right;
          }
        }
      }

      .cate-title {
        @include flex(center, space-between);
        position: relative;

        span {
          flex: auto;
          color: $primary-80;
          margin-left: 4px;
        }
      }
    }

    .addons-item {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 4px;
      padding: 8px 16px;
      border: 1px solid $gray-200;
      border-radius: 8px;

      .extras-title {
        font-weight: 500;
        margin-bottom: 8px;
      }

      .jack__switch__container {
        scale: 0.9;
      }
    }

    .addons-flex {
      @include flex(initial, initial);
      flex: 1;
      gap: 40px;
    }
  }
}
