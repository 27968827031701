@import 'common/scss/utils.scss';

#root-admin-table {
  position: relative;
  .jack_table__body {
    .jack_table__row {
      .jack_table__td {
        &:last-child {
          .jack_table__td__context {
            .text-node {
              width: 100%;
            }
          }
        }
      }
      .jack_table__tr {
        .root-page-admin-table-active {
          @include flex(center, initial);
        }

        .expand-icon {
          @include flex(center, center);
          width: 48px;
          height: 48px;
          cursor: pointer;

          &.expanded {
            &::before {
              background-position: -25px -101px;
            }
          }

          &::before {
            content: '';
            display: block;
            background: kyc-icon(-44px, -115px);
            width: 17px;
            height: 10px;
          }
        }
      }
    }

    .jack__no__data {
      display: none;
    }
  }

  &.active {
    .jack_table__body {
      .jack__no__data {
        display: block;
      }
    }
  }
}
