@import 'common/scss/utils.scss';

#hmac__modal__wrapper {
  text-align: center;
  .modal__content {
    padding: 0px 45px;
  }
  .step-1 {
    .icon {
      background: url('/assets/img/kyc_icons.svg') no-repeat -662px -94px;
      width: 63px;
      height: 63px;
      margin: 0 auto;
    }
    .detail {
      padding: 16px 20px 0px;

      font-size: 1rem;
      line-height: 1.625rem;
      color: $surface-high;
      .accent {
        color: $primary-80;
      }
    }
  }
  .step-2 {
    .hmac {
      @include flex(center, initial);
      padding: 16px 24px;
      border: 1px solid $gray-300;
      border-radius: 8px;
      font-size: 0.875rem;
      margin-bottom: 8px;
      .hmac-value {
        line-height: 1.5rem;
        color: $surface-high;
      }
      button {
        margin-left: auto;
        font-weight: 500;

        line-height: 1.25rem;
        text-align: right;
        text-decoration-line: underline;

        color: $primary-80;
      }
    }
    .hmac-detail {
      text-align: start;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      color: $surface-medium;
      position: relative;
      margin-left: 24px;
      &::before {
        content: '';
        position: absolute;
        right: calc(100% + 6.5px);
        background: url('/assets/img/kyc_icons.svg') no-repeat -144px -196px;
        width: 17px;
        height: 17px;
      }
    }
  }
  .hmac-submit-btn {
    padding: 15px 16px;
    background: $primary-100;
    border-radius: 8px;
    flex: 1;

    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4375rem;
    color: $surface-100;
  }
}
