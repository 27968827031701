@import 'common/scss/utils.scss';

@mixin h4 {
  font-weight: 700;
  color: $surface-high;
}

.edd-ra-config-controller {
  @include flex(initial, initial);
  flex-direction: column;
  // gap: 16px;
  margin-bottom: 16px;
  // padding: 16px 32px;
  background-color: $surface-100;
  // box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);

  article {
    flex: 1;
    // border-radius: 8px;
    padding: 16px 24px;
    &:not(:last-of-type) {
      border-bottom: 1px solid $gray-200;
    }
    & > div {
      @include flex(center, initial);
      gap: 16px;
      // padding: 11px 16px;
      background-color: $surface-100;
      // border: 1px solid $gray-300;
      border-radius: 8px;
      @include h4;
    }

    // &.disabled {
    //   opacity: 0.6;
    //   cursor: default;
    //   pointer-events: none;
    //   background-color: $gray-100;
    //   &.active {
    //     background-color: $primary-10;
    //   }
    //   & > div {
    //     background-color: transparent;
    //   }
    // }

    &:last-of-type {
      background-color: transparent;
      & > div {
        background-color: transparent;
        border: none;
      }
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $surface-medium;
    }
    .content {
      @include flex(initial, flex-end);
      flex: 1;

      .disabled {
        opacity: 0.6;
        cursor: default;
        .switch__button {
          cursor: default;
        }
      }
    }

    .jack__select {
      width: 192px;
      height: 48px;
      .jack__select__button {
        font-weight: 400;
        padding: 0 16px;
        .jack__select__arrow__icon {
          z-index: 101;
        }
      }
      .jack__select__options {
        top: 0px !important;
        border: 1px solid $primary-80 !important;
        border-radius: 8px !important;
      }
    }
  }

  // .button-group {
  //   @include flex(center, flex-end);
  //   margin-left: auto;
  //   gap: 8px;

  //   button {
  //     padding: 8px 12px;
  //     border: 1px solid $gray-200;
  //     border-radius: 8px;

  //     font-weight: 500;
  //     font-size: 0.875rem;
  //     line-height: 1.25rem;
  //     background-color: $surface-100;

  //     color: $surface-medium;

  //     &:last-child {
  //       color: $primary-80;
  //     }

  //     p {
  //       margin-left: auto;
  //       margin-right: 96px;
  //       font-weight: 400;
  //     }

  //     &[disabled] {
  //       color: $surface-disabled;
  //     }
  //   }
  // }
}
