@import 'common/scss/utils.scss';

.edd-setting-product-risk {
  .title {
    padding: 0 16px;
    @include flex(center, initial);
    height: 24px;
    color: $surface-high;
    cursor: pointer;

    h4 {
      font-weight: 700;
    }
    .score {
      @include flex(center, flex-end);
      gap: 24px;
      flex: 1;
      margin-right: 24px;

      .edit {
        visibility: hidden;
      }

      div {
        @include flex(center, initial);
        gap: 32px;

        &:first-child {
          span {
            color: $surface-high;
          }
        }

        span:first-child {
          color: $surface-medium;
          font-size: 14px;
          font-weight: 400;
        }
        span:last-child {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    i {
      width: 18px;
      height: 18px;
      background: kyc-icon(-24px, -110px);
      transform: rotate(270deg);
      cursor: pointer;
    }

    .control {
      @include flex(center, center);
      width: 120px;
      flex: unset;
    }
  }

  .button-group {
    @include flex(center, flex-end);
    gap: 8px;
    margin-left: auto;

    button {
      padding: 8px 12px;
      @include border($gray-200);
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: $surface-100;
      color: $surface-medium;

      &:last-child {
        color: $primary-80;
      }

      p {
        margin-left: auto;
        margin-right: 96px;
        font-weight: 400;
      }

      &[disabled] {
        color: $surface-disabled;
      }
    }
  }
  .item-container {
    .item-list {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 8px;
    }

    .item-append {
      margin-top: 8px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 700;
      color: $surface-high;
      @include border($gray-200);

      i {
        width: 20px;
        height: 20px;
        background: kyc-icon(-73px, -222px);
      }
      .jack__button__context {
        gap: 8px;
      }
    }
  }
  .hide {
    visibility: hidden !important;
  }
  .error {
    color: $error-100;
  }
}
