@import 'common/scss/utils.scss';

#modal-ra-item-nations-setting {
  @mixin h4 {
    h4 {
      font-size: 14px;
      font-weight: 400;
      color: $surface-medium;
    }
  }
  .condition {
    @include h4;
    @include flex(center, initial);

    .item {
      @include flex(center, initial);
      flex-basis: 260px;
      gap: 24px;

      &:last-child {
        flex-grow: 1;
      }
    }
  }

  .searchbox__filter {
    @include h4;
    margin-top: 16px;
    h4 {
      font-weight: 500;
      color: $surface-high;
    }

    .jack__search__form__field {
      margin-top: 8px;
      padding: 0 8px;

      input {
        @include border($surface-disabled);
      }
      button {
        margin-right: 8px;
      }
    }
  }

  .nations__container {
    @include h4;
    margin-top: 16px;
    position: relative;
    @include flex(initial, initial);
    flex-direction: column;
    gap: 16px;

    h4 {
      font-weight: 500;
      color: $surface-high;
    }
    .nations__table {
      .head {
        // border-bottom: 1px solid $gray-200;

        & > div {
          font-size: 14px;
          padding: 5px 16px 5px 5px;
          margin-top: 8px;
          @include flex(center, space-between);

          span {
            cursor: pointer;

            &.disabled {
              color: $surface-disabled;
              cursor: default;
            }
          }
        }
      }
      .content {
        min-height: 160px;
        max-height: 164px;
        overflow: auto;
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        position: relative;

        & > div:not(.nation) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          color: $surface-medium;
        }

        .nation {
          @include flex(center, space-between);
          padding: 5px;
          border-bottom: 1px solid $gray-200;

          &.checked {
            background-color: $primary-5;
          }

          & > span {
            @include flex(center, initial);
            gap: 8px;

            &:last-of-type {
              span {
                @include flex(initial, initial);
                font-size: 12px;
                color: $surface-medium;
                border: 1px solid $surface-disabled;
                border-radius: 100px;
                padding: 2px 8px;
              }
            }
          }
        }
      }
    }

    .control {
      @include flex(center, center);
      gap: 16px;

      .jack__button {
        @include border($gray-200);
        padding: 8px 12px;
        color: $primary-80;
        background-color: $surface-100;

        &.append {
          i {
            rotate: 90deg;
          }
        }
        &.remove {
          i {
            rotate: 270deg;
          }
        }

        &.disabled {
          color: $surface-disabled;
          opacity: 0.8;
          filter: grayscale(1);
        }

        .jack__button__context {
          gap: 8px;
        }

        i {
          width: 16px;
          height: 16px;
          background: kyc-icon(-96px, -117px);
        }
      }
    }
  }

  .info {
    font-size: 14px;
    color: $surface-medium;
    padding: 8px 0px;
    text-align: right;
  }

  .modal__footer {
    @include flex(center, center);

    button {
      flex: unset;
      &.footer__cancel {
        width: 162px;
      }
      &.footer__submit {
        width: 243px;
      }
    }
  }
}
