@import 'common/scss/utils.scss';

#subscribe-management-setting-account-subcode {
  position: relative;
  @include flex(baseline, space-between);
  // gap: 60px;
  height: 312px;

  .validator__form {
    width: 100%;
    position: relative;
    @include flex(baseline, space-between);
  }

  .header {
    @include flex(center, initial);
    font-size: 1rem;
    line-height: 1.5rem;
    color: $surface-high;
    margin-bottom: 18px;
    flex-basis: 273px;

    .title {
      margin-left: 8px;
      font-weight: 500;

      margin-right: 6px;
    }

    i.title-info {
      background: url('/assets/img/kyc_icons.svg') no-repeat -196px -318px;
      width: 16px;
      height: 16px;
      margin-top: 4px;
      cursor: pointer;
      position: relative;
    }
  }
  .content {
    flex: 1 0 0%;
    @include flex(initial, initial);
    gap: 130px;

    .jack__radio__group {
      gap: 16px;

      &:not(:has(.disabled)) {
        .jack__radio__item {
          & > div {
            margin-bottom: 16px;
          }
        }
      }

      .jack__radio__item {
        padding-top: 5px;

        & > div {
          @include flex(baseline, initial);
          gap: 8px;
          margin-top: 8px;
          padding-left: 28px;
          font-size: 14px;
        }

        .jack__input__container {
          width: 97px;
          input {
            color: $surface-high;
            &::placeholder {
              color: $surface-disabled;
            }
          }
        }

        .jack__radio__box {
          .radio-box {
            width: 20px;
            height: 20px;
            background: url('/assets/img/kyc_icons.svg') no-repeat -143.5px -223px;
          }
          &.checked {
            .radio-box {
              background: url('/assets/img/kyc_icons.svg') no-repeat -120px -223px;
            }
          }
          .radio-box-text {
            font-size: 1rem;
          }
        }
      }
    }

    .radio {
      flex-basis: 200px;
    }

    .preview {
      width: 204px;
      height: 148px;
      background: url('/assets/img/kyc_icons.svg') no-repeat -1021px 0px;
      background-repeat: no-repeat;
      position: relative;
      padding-top: 150px;

      & span:not(.info) {
        font-size: 14px;
        font-weight: 500;
        position: absolute;
        background-color: $surface-100;
        opacity: 0;
        width: 67px;
        top: 91px;
        user-select: none;
        padding-left: 3px;
        white-space: nowrap;
        overflow: hidden;
      }
      .info {
        @include flex(center, center);
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        color: $surface-medium;
        text-align: center;
      }

      &.left {
        background: url('/assets/img/kyc_icons.svg') no-repeat -1226px 0px;
        & span {
          opacity: 1;
          width: 30px;
          left: 27px;
        }
      }
      &.right {
        background: url('/assets/img/kyc_icons.svg') no-repeat -1021px -150px;
        & span:not(.info) {
          opacity: 1;
          left: 63px;
        }
      }
    }

    .input-container {
      width: 332px;
      // height: 70px;
      padding: 10px 16px;
      overflow: auto;
      resize: none;
      border: 1px solid $gray-300;
      border-radius: 8px;
      box-sizing: border-box;

      // text
      font-family: 'Noto Sans KR';
      font-size: 1rem;
      line-height: 1.5rem;

      &:focus {
        border: 1px solid $primary-80;
        box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .input-length {
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: $surface-disabled;
      margin-left: 8px;
    }
  }

  .button-group {
    @include flex(initial, space-between);
    gap: 8px;

    .jack__button {
      width: 50px;
      height: 36px;
      border: 1px solid $gray-200;
      background-color: $surface-100;

      &.cancel {
        & > div {
          color: $surface-medium;
        }
      }

      &.submit {
        & > div {
          color: $primary-100;
        }
      }

      &.disabled {
        & > div {
          color: $surface-disabled;
        }
      }
    }
  }
}

#admin-subscribe-subcode-popover-content.description {
  background: url('/assets/img/kyc_icons.svg') no-repeat -384px -546px;
  width: 316px;
  height: 73px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-35%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 18px 22px 18.5px 28px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
