@import 'common/scss/utils.scss';

#find-account {
  @include flex(initial, center);
  height: calc(100% - 48px);
  min-height: 800px;
  background: repeat-x top center/auto 67vh;

  &.admin {
    background-image: url('/assets/admin-bg.svg');
  }

  &.reviewer {
    background-image: url('/assets/reviewer-bg.svg');
  }

  #find-account-form-field {
    margin-top: 14vh;
    .find-title {
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 32px;
      color: $surface-100;

      i {
        background: kyc-icon(-9px, -551px);
        width: 127px;
        height: 19px;
      }
    }

    section.form-section {
      width: 400px;
      padding: 40px;
      max-height: 100%;
      overflow: auto;
      background-color: $surface-100;
      border-radius: 8px;
      box-shadow: 0px 0px 8px rgb(95 101 255 / 10%), 0px 2px 8px 4px rgb(95 101 255 / 5%);

      .find-category {
        @include flex(initial, initial);
        height: 48px;
        margin-bottom: 32px;
        cursor: pointer;

        p {
          @include flex(center, center);
          width: 50%;
          color: $surface-disabled;
          border-bottom: 2px solid $surface-disabled;

          &.active {
            color: $primary-80;
            border-color: $primary-80;
          }
        }
      }
    }
  }
}
