@mixin display-flex($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

.kyc__radio__group__container {
  .jack__radio__group {
    .jack__radio__box {
      &.checked {
        .radio-box {
          background: url('/assets/img/kyc_icons.svg') no-repeat -120px -223px;
        }
      }

      .radio-box {
        width: 20px;
        height: 20px;
        background: url('/assets/img/kyc_icons.svg') no-repeat -143.5px -223px;
      }

      .radio-box-text {
        font-size: 1rem;
      }
    }
  }
  .kyc__radio__error {
    font-size: 0.8125rem;
    line-height: 1.25rem;

    color: #f53c3c;
  }
}
