@import 'common/scss/utils.scss';

article.root-page-client-expand-admins {
  @include flex(baseline, initial);
  gap: 16px;

  h5 {
    font-weight: 500;
    font-size: 16px;
    width: 150px;
  }

  .aside-container {
    @include flex(center, initial);
    gap: 16px;
    flex: 1;

    .aside-item {
      @include flex(center, space-between);
      flex: 1;
      border: 1px solid $gray-200;
      border-radius: 8px;
      margin-bottom: 4px;
      padding: 8px 16px;
      font-weight: 500;

      div:first-of-type {
        @include flex(center, space-between);
      }

      .extras-title {
        font-size: 14px;
        color: $surface-high;
      }

      .jack__input__container {
        margin: 0 4px;
      }
    }
  }
}
