.static-chip {
  margin-right: 16px;
  position: relative;
  .check-icon {
    visibility: hidden;

    width: 23px;
    height: 23px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
    background: url('/assets/img/kyc_icons.svg') no-repeat -101.5px -246px;
  }
}
