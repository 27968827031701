@import 'common/scss/utils.scss';

.jack__search__form__field {
  &.focus {
    input.jack__input {
      border-color: $primary-80 !important;
    }
    .search-button {
      i {
        background: kyc-icon(-107.5px, -69px);
      }
    }
  }

  .jack__input__container {
    input.jack__input {
      &::placeholder {
        color: $gray-300;
      }
    }
  }

  .search-button {
    i {
      width: 16px;
      height: 16px;
      background: kyc-icon(-84px, -69px);
    }
  }
}
