@import 'common/scss/utils.scss';

@mixin h4 {
  h4 {
    font-size: 14px;
    font-weight: 400;
    color: $surface-medium;
    width: 80px;
  }
}

#modal-customfield-direct-dropdown {
  .condition {
    @include h4;

    .item {
      @include flex(baseline, initial);
      gap: 8px;
      margin-bottom: 16px;
      height: 76px;
    }
  }

  .category__container {
    position: relative;

    &.disabled {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $gray-100;
        opacity: 0.5;
      }
    }
  }
  .jack__input__container {
    flex-direction: column;
  }
}

#modal-customfield-direct-yesno {
  @include h4;

  .condition {
    .item {
      @include flex(baseline, initial);
    }
  }

  .category__container {
    margin-top: 16px;
    @include flex(initial, initial);
    flex-direction: column;
    gap: 16px;

    .category {
      @include flex(center, initial);
    }
  }

  .jack__input__container {
    flex: 1;
    flex-direction: column;
  }
}
