@import './utils.scss';

.jack__search__form__field {
  @include flex(center, initial);
  position: relative;
  width: 300px;
  height: 40px;

  .jack__input__container {
    width: 100%;
    height: 100%;

    input {
      padding-right: 43px;
      height: 100%;
    }
  }

  .search-button {
    @include flex(center, center);
    width: 40px;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;

    i {
      width: 18px;
      height: 18px;
      // background-color: gold;
      // background: url('/assets/img/cve_icons.svg') no-repeat -180px 0;
    }
  }
}
