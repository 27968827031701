.jack__alarm__wrapper {
  top: unset;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);

  .text__alarm {
    border-radius: 4px !important;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);

    &.success {
      background-color: $success-100 !important;
    }

    &.fail {
      background-color: $error-100 !important;
    }

    .result__icon {
      display: none;
    }
  }
}
