@import 'common/scss/utils.scss';

.jack__textarea__container {
  border: 1px solid $gray-200 !important;
  border-radius: 8px;

  &.focus {
    border: 1px solid $primary-80 !important;
  }

  &.readonly,
  &.disabled {
    &.focus {
      border-color: $gray-200 !important;
    }
  }
}
