@import 'common/scss/utils.scss';

.treeview__group {
  &.depth1 {
    padding: 16px 0;
    &:not(:last-child) {
      border-bottom: 1px solid $gray-300;
    }
  }
  &.padding {
    position: relative;
    height: 100%;
    padding-left: 24px;
  }

  &.depth2 {
    &.expand {
      &::before {
        content: '';
        width: 1px;
        height: calc(100% - 56px);
        border: 1px dashed $gray-300;
        display: inline-block;
        position: absolute;
        top: 54px;
        left: 30px;
      }
    }
  }
  &.expand {
    & > .treeview__node {
      .icon-expand {
        &.expandable {
          rotate: (90deg);
        }
      }
    }
  }
}

.treeview__node {
  @include flex(center, initial);
  gap: 8px;

  // selected
  &.selected:not(.active) {
    .treeview__node__label {
      .jack__input {
        border-color: $primary-80;
        background-color: $primary-10;
      }
    }
  }

  // active
  &.active {
    .treeview__node__label {
      .jack__input {
        color: $primary-80;
        border-color: $primary-80 !important;
      }
    }
  }

  .treeview__node__label {
    width: 318px;
    height: 56px;

    .jack__input__container {
      width: 100%;
      height: 100%;
      .jack__input {
        width: inherit;
        height: inherit;
        color: $surface-high;
        border-radius: 8px;
        border-color: transparent;
        background-color: transparent;
      }
    }
  }

  .icon-expand {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: $gray-500;
    border-radius: 50%;
    margin-left: 6px;
    transition: rotate 150ms ease;
    &.expandable {
      cursor: pointer;
      width: 16px;
      height: 16px;
      margin-left: 0px;
      background: kyc-icon(-90px, -177px);
    }
    &.expand {
      rotate: (90deg);
    }
    input[type='checkbox'] {
      visibility: hidden;
    }
  }
}
