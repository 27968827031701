@import './utils.scss';

.jack__loading {
  @include flex(flex-start, flex-start);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 55;
  width: 100%;
  height: 100%;

  .jack__loading__back {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.15;
  }

  .loading__spinner__wrap {
    @include flex(center, center);

    .loading__spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 55;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #fff url('../assets/loading.gif') no-repeat center;
    }
  }
}

.jack__loding__spinner {
  @include flex(center, center);
  width: 100%;
  height: 100%;

  .jack__loding__inner__spinner {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background: url('../assets/proccessing.svg') no-repeat center;
    background-size: 100%;
  }
}

.jack__proccessing__loding__type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .on__proccessing {
    width: 70%;
    height: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      background-color: white;
      height: 100%;
      width: 0.2rem;
      display: inline-block;
      margin: 0 0.1rem;

      -webkit-animation: stretch 1.2s infinite ease-in-out;
      animation: stretch 1.2s infinite ease-in-out;
    }
    .rect__aling_2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }
    .rect__aling_3 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
    .rect__aling_4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }
    .rect__aling_5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }

    @-webkit-keyframes stretch {
      0%,
      40%,
      100% {
        -webkit-transform: scaleY(0.4);
      }
      20% {
        -webkit-transform: scaleY(1);
      }
    }

    @keyframes stretch {
      0%,
      40%,
      100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
      }
      20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
      }
    }
  }
}

.jack__waiting__loading {
  @include flex(center, space-between);
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  transform: translate(-50%, -50%);

  & > div {
    width: 12px;
    height: 12px;
    background-color: #9fd9f6;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: waiting-bouncedelay 1.4s infinite ease-in-out both;
    animation: waiting-bouncedelay 1.4s infinite ease-in-out both;
  }

  .waiting_first {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .waiting_second {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes waiting-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes waiting-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
