@mixin flex($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
