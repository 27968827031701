@import 'common/scss/utils.scss';

#alchera-reviewer-content-layout {
  position: relative;
  @include flex(initial, center);
  width: 100%;
  height: calc(100% - 70px);
  background-color: $primary-5;
  overflow: overlay;

  #content-space {
    width: 100%;
    height: 100%;
    max-width: 1080px;
    #content-outlet {
      min-height: calc(100% - 48px);
    }
  }
}

@include desktop {
  #alchera-reviewer-content-layout {
    padding: 0 40px;
  }
}
