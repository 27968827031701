@import 'common/scss/utils.scss';
#root-admin-controller-title {
  @include flex(center, initial);

  .jack__select {
    width: 200px;
    height: 36px;
    margin-left: 10px;

    .jack__select__button {
      border-radius: 8px;
      font-size: 14px;
      border-color: $surface-medium;
    }

    .jack__select__options {
      top: 36px !important;

      .jack__select__item {
        height: 36px;
        font-size: 14px;
      }
    }
  }

  .customer-setting {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    z-index: 10;

    p {
      text-align: center;
      color: $surface-high;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 18px;
    }

    .search-customer {
      position: relative;
      display: flex;
      width: 420px;
      height: 56px;

      .jack__input__container {
        flex: 1;
        input {
          border-radius: 8px;
          font-size: 16px;
          padding-left: 20px;

          &::placeholder {
            color: $surface-disabled;
          }
        }
      }
      button.search-button {
        position: absolute;
        width: 40px;
        height: 100%;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        i {
          width: 16px;
          height: 16px;
          background: kyc-icon(-84px, -69px);
        }
      }

      &.focus {
        input.jack__input {
          border-color: $primary-80 !important;
        }
        button.search-button {
          i {
            background: kyc-icon(-107.5px, -69px);
          }
        }
      }
    }

    ul {
      position: absolute;
      top: 97px;
      left: 0;
      width: 100%;
      max-height: 128px;
      overflow: auto;
      border-radius: 8px;
      border: 1px solid $primary-80;

      li {
        @include flex(center, initial);
        width: 100%;
        height: 32px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        color: $surface-high;

        span {
          color: $primary-80;
        }

        &:hover:not(.no-data) {
          background-color: $primary-10;
        }
      }
    }
  }
}
