@import 'common/scss/utils.scss';

#kyc-sign-up-page {
  .client {
    .title {
      color: $surface-medium;
      margin-bottom: 8px;
    }

    .customer-input {
      position: relative;
      .check {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }

    .api-check-message {
      font-size: 12px;
      color: $primary-100;
      margin-top: 10px;
      &.error {
        color: $error-100;
      }
    }

    .next-field {
      @include flex(center, flex-end);
      width: 100%;
      gap: 10px;
      margin-top: 32px;

      button {
        &:first-child {
          @include flex(center, center);
          flex: 2;
          width: 120px;
          height: 60px;
          border-radius: 8px;
          background-color: #f6f6f6;

          & > div {
            color: $surface-medium !important;
            font-weight: 500;
            font-size: 16px;
          }
        }

        &:last-child {
          @include flex(center, center);
          flex: 3;
          width: 120px;
          height: 60px;
          border-radius: 8px;
          background-color: $primary-100;

          & > div {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }
}
