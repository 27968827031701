@import 'common/scss/utils.scss';

@mixin navigator {
  @include flex(center, space-between);
  width: 100%;
  padding-top: 40px;
  gap: 10px;

  & > button {
    @include flex(center, center);
    cursor: pointer;
    height: 60px;
    border-radius: 8px;

    &:first-child {
      flex: 2;
      background-color: #f6f6f6;
      & > div {
        color: $surface-medium;
      }
    }

    &:last-child {
      flex: 3;
      background-color: $primary-100;
      & > div {
        color: $surface-100;
      }
    }
  }
}

.kyc-find-id-result {
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
  }

  .success {
    .description {
      margin-top: 40px;
      text-align: center;
    }

    .notice-id {
      @include flex(center, center);
      margin: 24px;
      width: 270px;
      height: 64px;
      border: 1px solid $gray-300;
      border-radius: 8px;
      color: $primary-100;
    }

    .extra-info {
      @include flex(initial, space-around);
      padding: 0 20px;

      i {
        background: url('/assets/img/kyc_icons.svg') no-repeat -144px -196px;
        width: 17px;
        height: 17px;
        margin-top: 1px;
      }

      .text {
        font-size: 12px;
        color: $surface-medium;
      }
    }

    .navigator {
      @include navigator;
    }
  }

  .fail {
    .fail-icon {
      @include flex(center, center);
      height: 150px;
      i {
        background: url('/assets/img/kyc_icons.svg') no-repeat -578px -3px;
        width: 62px;
        height: 62px;
      }
    }

    .description {
      color: $error-100;
      text-align: center;
    }

    .text {
      color: $surface-medium;
      text-align: center;
      margin-top: 10px;
    }

    .navigator {
      @include navigator;
    }
  }
}
