@import './utils.scss';

.jack__accordion__section {
  display: flex;
  flex-direction: column;
  .jack__accordion_button {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    font-size: 13px;
    color: #444;
    transition: background-color 0.6s ease;
    padding: 0 10px;
    &.button {
      cursor: pointer;
    }

    &.active {
      i.accordion__arrow__icon {
        &::before {
          transform: rotate(180deg);
        }
      }
    }

    i.accordion__arrow__icon {
      @include flex(center, center);
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      &::before {
        content: '';
        display: block;
        width: 10px;
        height: 8px;
        background-image: url('../assets/accordion_arrow.svg') no-repeat center;
      }

      &.button {
        cursor: pointer;
      }
    }
  }
}
