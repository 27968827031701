@import 'common/scss/utils.scss';

#required-management-content {
  article.content-box {
    min-height: 160px;
    // @include flex(initial, initial);

    padding: 24px;
    background-color: $surface-100;
    // margin-bottom: 24px;

    .header {
      @include flex(center, initial);
      font-size: 1rem;
      line-height: 1.5rem;
      color: $surface-high;
      margin-bottom: 24px;

      .title {
        margin-left: 8px;
        font-weight: 500;
        margin-right: 32px;
      }
      .title-info {
        background: url('/assets/img/kyc_icons.svg') no-repeat -196px -318px;
        width: 16px;
        height: 16px;
        margin-top: 4px;
        cursor: pointer;
        position: relative;
        margin-left: -26px;
        margin-right: 32px;
        .description {
          background: url('/assets/img/kyc_icons.svg') no-repeat -390px -422px;
          width: 309px;
          height: 81px;

          position: absolute;
          left: 0%;
          top: 100%;
          transform: translateX(-7%);
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: $surface-medium;
          padding: 25px 18px 10px 18px;

          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
      .button-group {
        margin-left: auto;
        @include flex(center, initial);

        button {
          display: block;
          padding: 8px 12px;
          border: 1px solid $gray-200;
          border-radius: 8px;

          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.25rem;

          &:first-child {
            color: $primary-80;
            margin-right: 8px;
          }
          &:not(:nth-child(1)):last-child {
            color: $error-100;
          }
        }
      }
    }

    // .title {
    //   @include flex(center, initial);
    //   flex: 1;
    //   height: 40px;
    //   letter-spacing: -0.5px;
    // }

    .content {
      @include flex(center, initial);
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding: 10px 0px 10px 24px;
      background: $gray-100;
      border-radius: 8px;
      .title {
        font-weight: 500;
        color: $surface-medium;
        @include flex(center, initial);
        margin-right: 24px;

        &::before {
          content: '';
          display: block;
          background: url('/assets/img/kyc_icons.svg') no-repeat -144px -196px;
          width: 17px;
          height: 17px;
          margin-right: 6px;
        }
      }
      ul {
        @include flex(center, initial);
        color: $surface-medium;
        li {
          line-height: 30px;
          position: relative;
          &:not(:last-child) {
            margin-right: 24px;
          }

          &:not(:last-child)::after {
            content: ' ';
            // display: block;
            position: absolute;
            top: calc(50% - 2px);
            right: -12px;
            width: 4px;
            height: 4px;
            background: $surface-medium;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
