@import 'common/scss/utils.scss';

#root-admin-page-modify-fail {
  .modify-fail-info {
    p {
      margin-bottom: 14px;
    }

    p:first-child {
      @include flex(initial, center);
      font-size: 20px;
      color: $primary-100;
    }

    p:nth-child(2) {
      @include flex(initial, center);
      color: $surface-high;
    }

    p:last-child {
      @include flex(initial, center);
      width: 180px;
      height: 24px;
      background-color: #f6f6f6;
      padding: 4px 12px;
      border-radius: 8px;
      font-size: 14px;
      margin: 0 auto;
      color: $surface-medium;
    }
  }

  .modal__footer {
    .footer__cancel {
      display: none;
    }
  }
}
