@import 'common/scss/utils.scss';

#idcarddriver__modal__wrapper {
  text-align: center;
  .modal__header + div {
    font-size: 14px;
  }
  .modal__content__form {
    .status-container {
      @include flex(center, center);

      .status {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $surface-high;
        &.ok {
          color: $primary-100;
          font-weight: 500;
        }
      }
      .icon {
        width: 16px;
        height: 12px;
        margin: 5px;
        background: url('/assets/img/kyc_icons.svg') no-repeat -64px -47px;
      }

      .kyc__radio__group__container {
        width: 100%;
      }
      .jack__radio__group {
        gap: 16px;
        .jack__radio__item {
          border: 1px solid $surface-disabled;
          border-radius: 8px;
          padding: 16px;
          text-align: initial;
          &.checked {
            border: 1px solid $primary-100;
          }

          .jack__radio__box {
            .radio-box {
              width: 20px;
              height: 20px;
              background: url('/assets/img/kyc_icons.svg') no-repeat -143.5px -223px;
            }
            &.checked {
              .radio-box {
                background: url('/assets/img/kyc_icons.svg') no-repeat -120px -223px;
              }
            }
            .radio-box-text {
              font-size: 1rem;
            }
          }

          & > div {
            margin-top: 8px;
            font-size: 14px;

            &:not(.description) {
              p {
                opacity: 0.8;
                &::before {
                  opacity: 0.8;
                }
              }
            }
            p {
              text-indent: 24px;
              &:first-child {
                position: relative;
                &::before {
                  content: '';
                  position: absolute;
                  top: 8px;
                  left: 8px;
                  width: 2px;
                  height: 2px;
                  border: 2px solid #000;
                  border-radius: 50%;
                }
              }
            }

            &.flex-container {
              @include flex(initial, initial);
              border: 1px solid $surface-disabled;

              .flex-item {
                flex: 1;
                text-align: center;

                &:first-child {
                  border-right: 1px solid $surface-disabled;
                }

                div {
                  padding: 8px;
                }
                div:nth-of-type(1) {
                  background-color: $gray-100;
                }
              }
            }
          }
        }
      }

      .description {
        text-align: initial;
        margin-top: 30px;
        font-size: 14px;
        background-color: $gray-100;
        padding: 15px;
        border-radius: 8px;
        width: 100%;
        & > div {
          margin-bottom: 8px;
          &:nth-child(2) {
            color: $surface-medium;
          }
          h4 {
            margin-bottom: 8px;
          }
          p {
            padding-left: 6px;
          }

          &.grid-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 36px);
            justify-content: center;
            align-items: center;
            margin-bottom: 8px;

            .grid-item {
              height: 100%;
              @include flex(center, center);
              gap: 4px;
              text-align: center;
              background-color: $surface-100;
              border-bottom: 1px solid $surface-disabled;

              &:nth-child(2),
              &:nth-child(4) {
                border-right: 1px solid $surface-disabled;
              }

              &.header {
                text-align: initial;
                justify-content: initial;
                padding-left: 8px;
                background-color: $gray-200;
              }
              &:first-child {
                grid-row: 1/3;
              }
              &:last-child {
                grid-column: 2/4;
                border-bottom: none;
              }

              &.success {
                color: $success-80;
                font-weight: 700;
              }
              &.failed {
                color: $error-80;
                font-weight: 700;
              }
            }
          }
        }
      }
      &.step2 {
        flex-direction: column;
      }
    }
    hr {
      background: $gray-300;
      margin: 16px 40px;
      border: none;
      height: 1px;
    }
  }
}
