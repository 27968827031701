@import 'common/scss/utils.scss';

.jack__modal__portal {
  border-radius: 8px;
  overflow: hidden;
}

.modal__basic__wrapper {
  .modal__basic__wrapper {
    .modal__content__form {
      .modal__content {
        width: 100%;
        height: 100%;
        padding: 0 40px;
      }
    }
  }
  .modal__content__form {
    .modal__content {
      width: 100%;
      height: 100%;
      padding: 24px 40px 40px;
    }
  }

  .modal__footer {
    @include flex(center, flex-end);
    width: 100%;
    padding: 16px 30px 40px;
    gap: 10px;

    .footer__cancel {
      @include flex(center, center);
      height: 60px;
      flex: 2;
      border-radius: 8px;
      background-color: #f6f6f6;

      & > div {
        color: $surface-medium !important;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .footer__submit {
      @include flex(center, center);
      height: 60px;
      flex: 3;
      border-radius: 8px;
      background-color: $primary-100;

      & > div {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
