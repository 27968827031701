@import 'common/scss/utils.scss';

.jack__validator__error__message {
  position: unset !important;
  left: unset !important;
  top: unset !important;
  margin-top: 8px;
  // background-color: $error-10 !important;
  color: $error-100 !important;
  border-radius: 8px !important;
  padding: 0px !important;

  font-size: 0.75rem;
  line-height: 0.75rem;

  /* Error */
  background: transparent;
  color: $error-100;
}
