@import 'common/scss/utils.scss';

#root-client-register-provider {
  #root-modal-register-client {
    .customer-check {
      @include flex(initial, initial);
      flex-wrap: wrap;
      margin-top: 8px;
      margin-bottom: 20px;
      gap: 10px;

      .jack__input__container {
        width: 319px;
      }

      .jack__button {
        width: 84px;
        height: 56px;
        flex-shrink: 0;
        background-color: $surface-medium;
      }
    }

    .api-check-message {
      font-size: 12px;
      color: $primary-100;
      &.error {
        color: $error-100;
      }
    }
  }
}
