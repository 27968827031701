@import 'common/scss/utils.scss';

.jack__alarm__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;

  .jack__alarm__list {
    .text__alarm {
      white-space: nowrap;
      background-color: rgba(0, 0, 0, 0.9);
      border-radius: 2px;
      color: #fff;
      font-size: 1rem;
      line-height: 1.5rem;
      width: 400px;
      height: 60px;
      padding: 18px 24px;
      transition: opacity 1s;
      animation: 2s ease 3.5s 1 forwards fadeOut;

      &.success {
        i.result__icon {
          // background-position: -255px -29px; 아이콘
        }
      }

      &.fail {
        i.result__icon {
          // background-position: -270px 0; 아이콘
        }
      }

      &:not(:first-child) {
        margin-top: 4px;
      }

      .alert-info {
        @include flex(center, space-between);
        font-style: normal;

        i.result__icon {
          width: 12px;
          height: 13px;
          // background-image: url('/assets/img/cve_icons.svg');
          // background-repeat: no-repeat;
          background-color: gold;
          margin-right: 10px;
        }

        .alert-title {
          padding-bottom: 5px;
        }

        i.close__alarm__icon {
          margin-left: 8px;
          cursor: pointer;

          &::before {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background: url('../../assets/cancel_white.svg') no-repeat 0 0;
          }
        }
      }

      @keyframes fadeOut {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }
  }
}
