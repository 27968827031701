@import 'common/scss/utils.scss';

.picker-bgimg {
  padding: 10px 10px 5px 10px;
  &::before {
    content: '';

    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid $surface-100;
    width: 0;
    height: 0;
    left: 50%;
    top: -9px;

    transform: translateX(-50%);
    display: block;
    position: absolute;
    filter: drop-shadow(0px -1px 1px rgb(0 0 0 / 13%));
  }
}

div.chrome-picker {
  box-shadow: none !important;
  & > div > div.flexbox-fix:first-child {
    & > div:first-child {
      display: none;
    }
  }
  & > div > div.flexbox-fix:last-child {
    & > div:first-child {
      & > div > div > label {
        display: none !important;
      }
    }

    & > div:last-child {
      display: none;
    }
  }
}
