@import './utils.scss';
@import '../../../scss/utils.scss';

body.table__resizing {
  cursor: col-resize !important;
  user-select: none;
  * {
    cursor: col-resize !important;
  }
}

@mixin table-checkbox {
  .ssr-table-th-check,
  .ssr-table-row-check {
    @include flex(center, center);
    margin: 0 9px;
  }
}

// table container #######################################
.jack_table__wrapper {
  * {
    box-sizing: border-box;
  }
  border: 1px solid #eee;

  .jack_table {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    font-size: 12px;
    overflow: hidden;

    .jack_table_header__wrapper {
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
      ul.jack_table__head {
        @include table-checkbox;
        @include flex(center, initial);
        min-width: 1000px;
        height: 32px;
        margin: 0;
        padding: 0;

        div.jack_table__th {
          @include flex(center, initial);
          height: 100%;
          padding: 0 12px;
          color: #0c2b61;
          font-weight: 700;
          position: relative;
          min-width: 60px;

          &.order {
            cursor: pointer;
            div.jack_table__th__context {
              .jack_table__text {
                max-width: calc(100% - 14px) !important;
              }
            }
          }

          div.jack_table__th__context {
            @include flex(center, initial);
            width: calc(100% - 24px);
            max-width: calc(100% - 24px);
            position: absolute;

            .jack_table__text {
              @include ellipsis;
              max-width: 100%;
              word-break: keep-all;
            }

            i.jack_table__order__icon {
              width: 8px;
              height: 12px;
              vertical-align: middle;
              margin-left: 5px;
            }
          }

          .resizer__grap {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 60%;
            cursor: col-resize;

            &::after {
              content: '';
              display: block;
              width: 2px;
              height: 100%;
              background-color: #eee;
            }
          }
        }
      }
    }

    .jack_table_body__wrapper {
      height: calc(100% - 36px);
      padding-top: 4px;
      .jack_table__container {
        position: relative;
        width: 100%;
        height: 100%;
        .jack_table__body {
          position: relative;
          height: 100%;
          margin: 0;
          padding: 0;
          overflow: auto;

          li.jack_table__row {
            height: 36px;
            div.jack_table__tr {
              @include flex(center, initial);
              @include table-checkbox;
              width: 100%;
              min-width: 1000px;
              height: 100%;

              div.jack_table__td {
                @include flex(center, initial);
                @include ellipsis;
                padding: 0 12px;
                height: 100%;
                position: relative;
                color: #1d1d1d;

                &.cursor {
                  cursor: pointer;
                }

                div.jack_table__td__context {
                  @include flex(center, initial);
                  width: calc(100% - 24px);
                  max-width: calc(100% - 24px);
                  position: absolute;

                  .text-node {
                    @include ellipsis;
                    word-break: keep-all;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// // table tooltip #######################################
.jack_tooltip__table__cell {
  font-size: 12px;
  padding: 6px 20px;
  white-space: nowrap;
}

@include desktop {
  .jack_table__wrapper {
    .jack_table {
      .jack_table_header__wrapper {
        ul.jack_table__head {
          min-width: 800px;
        }
      }
    }
  }

  .jack_table__wrapper {
    .jack_table {
      .jack_table_body__wrapper {
        .jack_table__container {
          .jack_table__body {
            li.jack_table__row {
              div.jack_table__tr {
                min-width: 800px;
              }
            }
          }
        }
      }
    }
  }
}