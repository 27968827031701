@import 'common/scss/utils.scss';

.jack__no__data {
  .content__wrapper {
    .jack__no__data__icon {
      background: kyc-icon(-306px, -159px);
      width: 148px;
      height: 108px;
      transform: unset;
    }

    .jack__no__data__text {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
