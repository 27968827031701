@import 'common/scss/utils.scss';

#modal-customfield-config {
  .modal__content {
    div {
      text-align: center;
      color: $surface-high;

      span {
        color: $primary-100;
      }
    }
  }
}
