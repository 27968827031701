@import './utils.scss';

.jack__button {
  position: relative;
  display: block;
  line-height: initial;
  font-family: inherit;
  font-size: 14px;
  outline: none;
  overflow: hidden;
  user-select: none;
  padding: 0 10px;
  cursor: pointer;
  transition: background-color 0.2s;

  &.disabled {
    cursor: initial;
    background-color: #e6e6e6;
    opacity: 0.5;
    color: #c5c6ca;
  }

  .jack__button__context {
    letter-spacing: -0.14px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
}
