@import 'common/scss/utils.scss';

.nation-label {
  transition: all 0.3s ease;
  &.disabled {
    background-color: $gray-100 !important;
    box-shadow: none !important;
    .title {
      color: $surface-medium;
    }
  }

  .title {
    padding: 0 16px;
    @include flex(center, initial);
    height: 38px;
    color: $primary-100;
    margin-bottom: 0px;
    transition: margin-bottom 0.3s ease;
    &.expand {
      margin-bottom: 16px;
    }
    h4 {
      font-weight: 700;
      margin-left: 24px;
      width: 330px;
      cursor: pointer;
    }

    .active {
      @include flex(center, flex-end);
      flex: 1;
    }

    .score {
      @include flex(center, flex-end);
      gap: 24px;
      flex: 1;
      margin-right: 24px;

      &.edit {
        color: $surface-high;
      }

      .jack__input__container.basic {
        input.jack__input {
          height: 32px;
          padding: 0 8px 0 16px;
          text-align: right;
        }
      }

      div {
        @include flex(center, initial);
        gap: 4px;

        span {
          font-weight: 500;
        }
      }
    }
    i {
      width: 18px;
      height: 18px;
      background: kyc-icon(-24px, -110px);
      cursor: pointer;
      &.expand {
        background: kyc-icon(-25px, -96px);
      }
    }

    .control {
      @include flex(center, center);
      width: 120px;
      flex: unset;
    }

    .jack__input__container {
      input.jack__input {
        height: 40px;
        padding: 0 16px;
      }
    }
  }

  .button-group {
    @include flex(center, flex-end);
    margin-left: auto;
    gap: 8px;

    button {
      padding: 8px 12px;
      border: 1px solid $gray-200;
      border-radius: 8px;

      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: $surface-100;

      color: $surface-medium;

      &:last-child {
        color: $primary-80;
      }

      p {
        margin-left: auto;
        margin-right: 96px;
        font-weight: 400;
      }

      &[disabled] {
        color: $surface-disabled;
      }
    }
  }

  .item-container {
    .item-list {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 8px;
    }

    .item-append {
      margin-top: 8px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 700;
      color: $surface-high;
      @include border($gray-200);

      i {
        width: 20px;
        height: 20px;
        background: kyc-icon(-73px, -222px);
      }
      .jack__button__context {
        gap: 8px;
      }
    }
  }

  .hide {
    visibility: hidden !important;
  }
  .error {
    color: $error-100;
  }

  .jack__button.label-append {
    color: $surface-high;
    font-size: 16px;
    font-weight: 700;

    i {
      margin-right: 8px;
      width: 20px;
      height: 20px;
      background: kyc-icon(-73px, -222px);
    }
  }
}
