@import './utils.scss';

.jack__select {
  position: relative;
  width: 300px;
  height: 40px;
  cursor: pointer;
  transition: all 0.2s ease;

  &.error {
    border: 1px solid #ff5974;
  }

  &.active {
    .jack__select__button {
      border: 1px solid #9fd9f6;
      .jack__select__arrow__icon {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.disabled {
    background-color: #f5f5f5;
    cursor: initial;
    .jack__select__button {
      color: #a5adba;
    }
  }

  .jack__select__button {
    @include flex(center, space-between);
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 14px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    font-size: 14px;
    border-radius: 4px;

    .button__title {
      @include flex(center, initial);
      width: calc(100% - 20px);
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #363636;
    }

    .jack__select__arrow__icon {
      &::before {
        content: '';
        display: block;
        width: 15px;
        height: 10px;
        background: url('../assets/select_arrow.svg') no-repeat center;
      }
    }
  }

  .jack__select__options {
    position: absolute;
    width: 100%;
    height: 202px;
    z-index: 100;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    overflow: auto;

    .jack__select__button {
      font-size: 14px;
      border-radius: 4px;
    }

    .jack__select__item {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 12px;
      padding: 0 16px;
      white-space: nowrap;
      color: #191919;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: background-color 0.1s;
      border-radius: 4px;

      &:hover {
        background-color: #f5fbff;
      }

      &.active {
        font-weight: 900;
        color: #006dbc;
      }

      &.disabled {
        cursor: default;
        color: #c5c6ca;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
