@import 'common/scss/utils.scss';

#admin-system-error-modal {
  .blue-text {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: $primary-100;
    text-align: center;
  }

  .gray-text {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 26px;
    color: $surface-high;
    text-align: center;
  }

  .info {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 26px;
    color: $surface-high;
    text-align: center;
  }

  .error-code {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 26px;
    color: $surface-medium;
    text-align: center;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 32px;
    background-color: $primary-100;
    width: 144px;
    height: 48px;
    color: $surface-100;
    font-size: 16px;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
  }
}
