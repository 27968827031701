@import 'common/scss/utils.scss';

.admin-manage-edd-promotion {
  @include flex(center, center);
  flex-direction: column;
  background-color: $surface-100;
  height: 484px;
  color: $surface-medium;

  &-icon {
    background: kyc-icon(-662px, -164px);
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
  }
}
