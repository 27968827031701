@import 'common/scss/utils.scss';

#root-admin-page-modify-confirm {
  .modify-info {
    @include flex(initial, space-around);

    p.name {
      @include flex(center, initial);
      span:first-child {
        font-weight: 500;
      }

      span:last-child {
        font-weight: normal;
        font-size: 12px;
        color: $surface-medium;
        margin-left: 8px;
      }
    }
    p.into {
      span {
        font-weight: normal;
      }

      span:first-child {
        color: $surface-high;
      }

      span:last-child {
        color: $primary-100;
      }

      i {
        width: 16px;
        height: 12px;
        background: kyc-icon(-64px, -47px);
        margin: 0 4px;
      }
    }
  }

  hr {
    width: 80%;
    border: none;
    height: 1px;
    background-color: $gray-200;
    margin: 40px 10% 20px;
  }

  .description {
    color: $surface-high;
    p {
      @include flex(initial, space-around);
      padding: 4px 0;
    }
  }
}
