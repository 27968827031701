@import 'common/scss/utils.scss';

#reviewer-review-table {
  .expand-icon {
    @include flex(center, center);
    width: 48px;
    height: 48px;
    cursor: pointer;

    &.expanded {
      &::before {
        background-position: -25px -101px;
      }
    }

    &::before {
      content: '';
      display: block;
      background: kyc-icon(-44px, -115px);
      width: 17px;
      height: 10px;
    }
  }

  .jack__no__data__text {
    &::before {
      content: '심사를 수행할 항목이 없습니다.';
    }
  }
}
