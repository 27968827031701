@import './utils.scss';
#jack__absolute__modal__wrapper {
  @include flex(center, center);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;

  .jack__modal__portal {
    background-color: #fff;
  }
}

.jack__modal__background__screen {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #000000;
  opacity: 0.4;
  z-index: -1;
}
