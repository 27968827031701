@import 'common/scss/utils.scss';

#admin-manage-theme-logo {
  @include flex(center, initial);
  margin-bottom: 24px;

  h4 {
    flex-basis: 241px;
    font-weight: 500;
  }
  &.edit {
    align-items: initial;
    flex-direction: column;
    margin-bottom: 48px;

    h4 {
      margin-bottom: 24px;
      flex-basis: 0px;
    }
    & > div {
      padding-left: 16px;
    }
  }

  .jack__radio__group {
    gap: 8px;
    .jack__radio__item {
      .jack__radio__box {
        .radio-box {
          width: 20px;
          height: 20px;
          background: url('/assets/img/kyc_icons.svg') no-repeat -143.5px -223px;
        }
        &.checked {
          .radio-box {
            background: url('/assets/img/kyc_icons.svg') no-repeat -120px -223px;
          }
        }
        .radio-box-text {
          font-size: 1rem;
        }
      }
    }
  }

  & img {
    max-width: 100px;
    max-height: 20px;
    border: 1px solid $gray-200;
  }

  .logo-input-container {
    margin: 8px 0;
    div {
      position: relative;
      margin-bottom: 8px;
      @include flex(flex-start, initial);

      img {
        margin-right: 16px;
      }

      input {
        visibility: hidden;
        display: none;
      }

      label {
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;

        &.required {
          font-weight: 500;
          background-color: $gray-100;
          color: $surface-medium;
          width: 164px;
          height: 32px;
          border-radius: 8px;
          padding: 6px 39px;
          text-decoration: none;
        }
      }
    }

    p {
      font-size: 12px;
      color: $surface-medium;
      @include flex(center, initial);

      &::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $surface-disabled;
        margin-right: 10px;
      }
    }
  }
}

.logo__image__error {
  @include flex(center, center);
  flex-direction: column;
  padding: 40px;

  h2 {
    color: $surface-high;
    line-height: 36px;
    margin-bottom: 24px;
  }

  p:last-of-type {
    margin-bottom: 32px;
  }

  button {
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    width: 420px;
    height: 60px;
    padding: 15px 16px;
    border-radius: 8px;
    background-color: $primary-100;
    color: $surface-100;
  }
}
