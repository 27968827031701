@import 'common/scss/utils.scss';

#edit_info__modal__wrapper {
  @mixin info-input {
    border: 1px solid #ced1d2;
    border-radius: 8px;
  }
  .name-check {
    @include flex(initial, initial);
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 20px;
    gap: 10px;
    .jack__input__container {
      width: calc(100% - 94px);
    }

    .jack__button {
      width: 84px;
      height: 56px;
      flex-shrink: 0;
      background-color: $surface-medium;
    }

    .api-check-message {
      font-size: 0.75rem;
      color: $primary-100;
      &.error {
        color: $error-100;
      }
    }
  }
  .jack__checkbox__container {
    .jack__checkbox__label {
      font-size: 0.75rem;
      line-height: 0.75rem;
      // color: $surface-disabled;
    }
    &.require {
      .jack__checkbox__label {
        color: $primary-100;
      }
    }
  }
}
