@import 'common/scss/utils.scss';

#subscribe-management-setting-message {
  position: relative;
  @include flex(baseline, space-between);
  // gap: 60px;

  .header {
    @include flex(center, initial);
    font-size: 1rem;
    line-height: 1.5rem;
    color: $surface-high;
    margin-bottom: 18px;
    flex-basis: 273px;

    .title {
      margin-left: 8px;
      font-weight: 500;

      margin-right: 6px;
    }

    i.title-info {
      background: url('/assets/img/kyc_icons.svg') no-repeat -196px -318px;
      width: 16px;
      height: 16px;
      margin-top: 4px;
      cursor: pointer;
      position: relative;
    }
  }
  .content {
    flex: 1 0 0%;
    @include flex(initial, initial);
    gap: 20px;

    .jack__radio__item {
      min-height: 80px;
      padding-top: 5px;
      .jack__radio__box {
        .radio-box {
          width: 20px;
          height: 20px;
          background: url('/assets/img/kyc_icons.svg') no-repeat -143.5px -223px;
        }
        &.checked {
          .radio-box {
            background: url('/assets/img/kyc_icons.svg') no-repeat -120px -223px;
          }
        }
        .radio-box-text {
          font-size: 1rem;
        }
      }
    }

    .textarea > div {
      width: 372px;
      min-height: 80px;
    }

    .input-container {
      width: 332px;
      // height: 70px;
      padding: 10px 16px;
      overflow: auto;
      resize: none;
      border: 1px solid $gray-300;
      border-radius: 8px;
      box-sizing: border-box;

      // text
      font-family: 'Noto Sans KR';
      font-size: 1rem;
      line-height: 1.5rem;

      &:focus {
        border: 1px solid $primary-80;
        box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1), 0px 2px 8px 4px rgba(95, 101, 255, 0.05);
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .input-length {
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: $surface-disabled;
      margin-left: 8px;
    }
  }

  .button-group {
    @include flex(initial, space-between);
    gap: 8px;

    .jack__button {
      width: 50px;
      height: 36px;
      border: 1px solid $gray-200;
      background-color: $surface-100;

      &.cancel {
        & > div {
          color: $surface-medium;
        }
      }

      &.submit {
        & > div {
          color: $primary-100;
        }
      }

      &.disabled {
        & > div {
          color: $surface-disabled;
        }
      }
    }
  }
}

#admin-subscribe-message-popover-content.description {
  background: url('/assets/img/kyc_icons.svg') no-repeat -384px -546px;
  width: 316px;
  height: 73px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-35%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 18px 27px 18.5px 32px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
