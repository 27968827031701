@import 'common/scss/utils.scss';

.term-table-row-nav {
  ul {
    @include flex(initial, initial);
    box-sizing: content-box;

    li {
      width: auto;
      font-weight: 500;
      font-size: 18px;
      padding: 6px 10px;
      color: $surface-disabled;
      border-bottom: 2px solid $surface-disabled;
      cursor: pointer;

      &.active {
        color: #000;
        border-color: #000;
      }
    }
  }
}
