@import 'common/scss/utils.scss';

@mixin h4 {
  h4 {
    font-size: 16px;
    font-weight: 500;
    color: $primary-80;
  }
}

#reviewer-result-table-expand-content-custom {
  @include flex(initial, initial);
  flex-direction: column;
  width: 100%;
  padding: 24px 0 24px 56px;
  gap: 16px;

  article {
    & > h4 {
      margin-bottom: 16px;
    }
  }

  ul.info-section {
    li {
      @include flex(center, initial);
      margin-bottom: 16px;

      & > p {
        font-size: 16px;
        &.warning {
          color: $error-100;
        }
        &.disabled {
          color: $surface-disabled;
        }
      }

      .label {
        flex-shrink: 0;
        width: 140px;
        margin-right: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;

        h4 {
          // elipsis
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }

      h4 {
        color: $surface-high;
      }

      .jack__button {
        width: 110px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        padding: 8px 12px;
        color: $surface-medium;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }

  .reviewer-result-edd {
    @include h4;
    font-size: 16px;
  }
  .reviewer-result-ra-result {
    @include h4;
    font-size: 16px;

    ul.info-section {
      li {
        @include flex(center, initial);
        gap: 16px;

        h4 {
          width: 140px;
          margin-right: 8px;
        }
      }
    }
  }
  .reviewer-result-survey {
    @include h4;
    font-size: 16px;
  }
}

.reviewer-image-section-modal {
  &.expired {
    border: 0;

    .close-btn {
      top: -30px;
      right: 2px;
    }
    i.image-expired {
      width: 640px;
      height: 400px;
      background: url('/assets/img/kyc_icons.svg') no-repeat -740px -330px;
      position: relative;
    }
  }
}
