@import 'common/scss/utils.scss';
#kyc-sign-up-page {
  .agreement {
    .jack__checkbox__container {
      width: max-content;

      &.circular {
        .jack__checkbox__label {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .terms-check {
      @include flex(initial, initial);
      margin-top: 28px;
      .jack__checkbox__container {
        height: 42px;
        .jack__checkbox {
          @include flex(center, center);
          width: 21px !important;
          height: 21px !important;
          background: unset;

          &::before {
            content: '';
            display: block;
            width: 13px;
            height: 10px;
            background: url('/assets/img/kyc_icons.svg') no-repeat;
            background-position: -88px -252px;
          }
        }

        &.checked {
          .jack__checkbox {
            &::before {
              background-position: -46px -252px;
            }
          }
        }
      }

      .jack__accordion__section {
        .jack__accordion_button {
          background-color: transparent;
          font-size: 12px;
          padding: 0;
        }

        .accordion__content {
          background-color: transparent;

          .agreement-content {
            height: 180px;
            padding: 8px 16px;
            font-size: 12px;
            border: 1px solid $gray-200;
            overflow: auto;
          }
        }
      }
    }

    .next-field {
      @include flex(center, flex-end);
      width: 100%;
      gap: 10px;
      margin-top: 32px;

      button {
        &:first-child {
          @include flex(center, center);
          flex: 2;
          width: 120px;
          height: 60px;
          border-radius: 8px;
          background-color: #f6f6f6;

          & > div {
            color: $surface-medium !important;
            font-weight: 500;
            font-size: 16px;
          }
        }

        &:last-child {
          @include flex(center, center);
          flex: 3;
          width: 120px;
          height: 60px;
          border-radius: 8px;
          background-color: $primary-100;

          & > div {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }
}
