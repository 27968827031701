@import 'common/scss/utils.scss';

#setting-page-table-sensitive {
  @include flex(center, space-around);
  width: 100%;
  height: 72px;
  background-color: $surface-100;
  position: relative;
  margin-bottom: 54px;

  .title {
    color: $surface-high;
    font-weight: 500;
  }

  &.active {
    border: 1px solid $primary-80;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 1px 8px rgb(0 0 0 / 10%);

    .jack__button {
      background-color: $primary-100;
      color: $surface-100;
    }

    .notice {
      @include flex(center, center);
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -34px;
      color: $primary-60;
      font-size: 14px;
    }
  }

  .jack__button {
    width: 144px;
    height: 48px;
    background-color: $gray-100;
    color: $surface-medium;
  }

  .notice {
    display: none;
  }
}
