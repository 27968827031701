@import 'common/scss/utils.scss';

.custom-palette {
  @include flex(initial, initial);
  position: relative;

  .dynamic-chip {
    position: relative;
    margin-right: 16px;
    .check-icon {
      width: 23px;
      height: 23px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(2);
      background: url('/assets/img/kyc_icons.svg') no-repeat -101.5px -246px;
    }

    .noneRemoveChip {
      display: none;
    }

    .remove-chip {
      position: absolute;
      width: 20px;
      height: 20px;
      border-top-right-radius: 8px;

      background: $surface-high;
      top: 0px;
      right: 0px;

      .remove-icon {
        width: 20px;
        height: 20px;
        background: url('/assets/img/kyc_icons.svg') no-repeat -22px -198px;
        transform: scale(0.8);
      }
    }
  }
}
