.jack__accordion__section {
  .jack__accordion_button {
    .accordion__arrow__icon {
      &::before {
        width: 17px !important;
        height: 10px !important;
        background: url('/assets/img/kyc_icons.svg') no-repeat -44px -115px;
      }
    }

    &.active {
      &::before {
        background-position: -45px -101px;
      }
    }
  }
}
