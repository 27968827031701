.jack__no__data {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-style: normal;
  .content__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .jack__no__data__icon {
      width: 145px;
      height: 100px;
      transform: translateX(-10px);
      background: url('../assets/noData.svg') no-repeat center;
    }

    .jack__no__data__text {
      margin-top: 10px;
      font-weight: 700;
      font-size: 14px;
    }
  }
}
