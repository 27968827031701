@import 'common/scss/utils.scss';

#admin-edd-ra-description-popover-content {
  .modal__content {
    @include flex(center, center);
    flex-direction: column;
    gap: 16px;

    h3 {
      font-size: 16px;
      font-weight: 500;
    }

    div {
      padding: 16px;
      border-radius: 8px;
      background-color: $background-100;
      @include flex(center, center);
      width: 326px;
    }
    i {
      width: 292px;
      height: 226px;
      background: kyc-icon(0px, -626px);
    }
  }
}
