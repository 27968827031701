@import 'common/scss/utils';

#modal-ra-item-nations-mode-setting {
  .modal__content {
    @include flex(center, initial);
    flex-direction: column;
  }
  .modal__footer {
    flex-direction: row-reverse;

    .footer__submit {
      flex: 2 1;
      background-color: $gray-100;
      color: $surface-medium;
    }
    .footer__cancel {
      flex: 3 1;
      background-color: $primary-100;
      & > div {
        color: $surface-100 !important;
      }
    }
  }
}
