@import './utils.scss';

.jack__progress__content {
  position: relative;
  width: 100%;
  height: 100%;

  .ani-progress-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;

    &.uploaded {
      opacity: 0;
    }

    .ani-progress-bar-wrapper {
      width: 100%;
      height: 6px;
      border-radius: 5px;
      background-color: #e6e6e6;
      overflow: hidden;
      .ani-progress-bar {
        height: 100%;
        border-radius: 5px;
        background-color: #006dbc;
        transition: width 0.5s;
      }
    }

    .ani-progress-pointer-wrppaer {
      position: absolute;
      top: 0;
      transition: left 0.5s;
      transform: translate(-25%, -25%);

      .ani-progress-ball {
        position: relative;
        height: 10px;
        width: 10px;
        background-color: #fff;
        border: 3px solid #003288;
        border-radius: 50%;
        z-index: 10;

        .ani-progress-pointer {
          width: 59px;
          height: 23px;
          position: absolute;
          left: -50%;
          bottom: 16px;
          transform: translateX(-42%);

          .ani-progress-pointer-box {
            @include flex(center, center);
            width: 100%;
            height: 100%;
            border-radius: 7px;
            background-color: #006dbc;
            color: #fff;

            .ani-progress-box-progress {
              font-size: 15px;
            }
            .ani-progress-box-percent {
              font-size: 8px;
            }
          }

          .ani-progress-arrow {
            position: absolute;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid #006dbc;
          }
        }
      }
    }
  }

  .ani-progress-comment {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 100%;
    transition: all 0.5s;
    opacity: 0;

    &.uploaded {
      opacity: 1;
      .upload-success {
        width: 100%;
        height: 100%;

        .jack__waiting__loading {
          opacity: 1;
        }

        .floating-text {
          opacity: 1;
          .success-text {
            transform: translateY(0);
          }

          .analyze-notice {
            transform: translateY(0);
          }
        }
      }
    }

    .upload-success {
      @include flex(center, center);
      position: relative;
      transition: all 0.8s;

      .jack__waiting__loading {
        opacity: 0;
        top: 38%;
      }

      .floating-text {
        width: 100%;
        position: absolute;
        top: 50%;
        opacity: 0;
        .success-text {
          color: #006dbc;
          font-size: 12px;
          font-weight: bold;
          transform: translateY(4px);
          transition: all 1.2s;
          text-align: center;
        }

        .analyze-notice {
          font-size: 11px;
          transform: translateY(8px);
          transition: opacity 1.2s;
          transition: transform 1.8s;
          text-align: center;
        }
      }
    }
  }
}
