@import 'common/scss/utils.scss';

#root-page-guidance-delete-policy {
  .modal__content {
    p {
      @include flex(center, center);
      margin-bottom: 10px;
      color: $surface-high;
    }
  }
  .modal__footer {
    .footer__submit {
      background-color: $error-100;
    }
  }
}
