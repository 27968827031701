@import 'common/scss/utils.scss';

.jack_table__wrapper {
  border: none;
  .jack_table {
    padding: 0;

    .jack_table_header__wrapper {
      border-bottom: 1px solid $primary-80;
      .jack_table__head {
        height: 48px !important;
        .jack_table__th {
          .jack_table__text {
            color: $surface-medium;
            font-size: 14px;
            font-weight: normal;
          }
          &.order {
            .jack_table__order__icon {
              background: kyc-icon(-77px, -170px);
              width: 8px;
              height: 6px !important;

              &.asc {
                background-position: -77px -191px;
              }

              &.desc {
                background-position: -77px -182px;
              }
            }
          }
        }
      }
    }

    .jack_table_body__wrapper {
      height: calc(100% - 50px);
      .jack_table__container {
        .jack_table__body {
          overflow: overlay !important;
          min-height: calc(100vh - 300px);

          .jack_table__row {
            border-bottom: 3px solid $primary-5;
            font-size: 14px;
          }

          .jack__no__data {
            position: unset;
            width: 100%;
          }
        }
      }
    }
  }
}
