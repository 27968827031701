@import 'common/scss/utils.scss';

section.root-guidance-privacy-table-tr-expand {
  border-top: 3px solid $primary-5;
  padding: 50px 72px;

  #guidance-privacy {
    h3.term-subject {
      font-weight: 500;
      font-size: 16px;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    .term-content {
      margin-top: 20px;

      .term-title {
        @include flex(center, initial);
        font-weight: 500;
        margin-bottom: 8px;
        font-size: 16px;

        .jack__button {
          width: 117px;
          height: 36px;
          margin-left: auto;
          background-color: $surface-100;
          border: 1px solid $gray-200;

          & > div {
            font-size: 14px;
            font-weight: 500;
            color: $primary-80;
          }
        }

        &::before {
          content: '';
          display: block;
          width: 4px;
          height: 16px;
          margin-right: 8px;
          background-color: $surface-high;
        }
      }

      .inner-html {
        padding: 16px;
        width: 100%;
        max-height: 320px;
        border: 1px solid $gray-200;
        overflow: auto;
        margin-bottom: 32px;
        font-size: 14px;
      }

      .html-textarea {
        background-color: $surface-100;
        border-radius: 0;
        border: 1px solid $gray-200 !important;

        textarea {
          color: $surface-medium;
          background-color: $surface-100;
        }
      }
    }
  }

  .expand-utils {
    @include flex(initial, center);
    gap: 10px;
    padding-top: 50px;

    .jack__button {
      width: 202px;
      height: 48px;
      background-color: $surface-100;

      & > div {
        &::before {
          content: '';
          display: block;
          background: url('/assets/img/kyc_icons.svg') no-repeat;
          margin-right: 4px;
        }
      }

      &:first-child {
        border: 1px solid $error-100;
        & > div {
          color: $error-100;
          &::before {
            background-position: -6px -353px;
            width: 18px;
            height: 21px;
          }
        }
      }

      &:last-child {
        border: 1px solid $primary-80;
        & > div {
          color: $primary-80;
          &::before {
            background-position: -30px -353px;
            width: 18px;
            height: 21px;
          }
        }
      }
    }
  }
}
