@import 'common/scss/utils.scss';

#kyc-admin-reviewer-popover-content {
  @include flex(initial, space-between);
  flex-direction: column;
  width: 160px;
  height: 234px;
  padding: 20px;

  .top-box {
    .name {
      color: $surface-high;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 10px;
    }
    .my-name {
      color: $surface-medium;
      font-size: 12px;
    }

    hr {
      border: none;
      height: 1px;
      margin: 6px 0;
      background-color: $gray-200;
    }

    .company-name {
      color: $surface-medium;
      font-size: 12px;
    }
  }

  .bottom-box {
    @include flex(center, space-between);
    flex-direction: column;
    height: 80px;

    @mixin util-button {
      @include flex(center, initial);
      width: 100%;
      height: 34px;
      font-size: 14px;
      font-weight: 500;
      color: $surface-high;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        margin-right: 8px;
      }
    }

    .change-my-info {
      @include util-button;
      @include flex(center, initial);
      &::before {
        background: kyc-icon(-170px, -163px);
        width: 20px;
        height: 19px;
      }
    }

    .log-out {
      @include util-button;
      @include flex(center, initial);
      &::before {
        background: kyc-icon(-94px, -43px);
        width: 21px;
        height: 20px;
      }
    }
  }
}
