@import 'common/scss/utils.scss';

.edd-setting-ra-weight {
  .control {
    @include flex(center, space-between);
    .tooltip {
      @include flex(center, center);
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      color: $surface-medium;
    }
    i.tooltip-info {
      background: kyc-icon(-196px, -318px);
      width: 16px;
      height: 16px;
      cursor: pointer;
      position: relative;
    }
    .extra {
      @include flex(center, initial);
      gap: 32px;
      font-size: 14px;
      font-weight: 500;
      color: $surface-high;
      span {
        @include flex(center, center);
        gap: 8px;
      }
      span:last-child {
        font-weight: 500;
        font-size: 16px;
        color: $primary-100;
      }
    }
    .button-group {
      @include flex(center, flex-end);
      gap: 8px;

      button {
        padding: 8px 12px;
        @include border($gray-200);
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: $surface-100;
        color: $surface-medium;

        &:last-child {
          color: $primary-80;
        }

        p {
          margin-left: auto;
          margin-right: 96px;
          font-weight: 400;
        }

        &[disabled] {
          color: $surface-disabled;
        }
      }
    }
  }
  section {
    margin-top: 8px;

    @include flex(initial, initial);
    flex-direction: column;
    gap: 8px;

    .footer {
      margin: 8px 8px 0 auto;
      @include flex(flex-end, initial);
      flex-direction: column;
      gap: 8px;

      div {
        display: inline-flex;
        gap: 24px;
      }

      span {
        @include flex(center, initial);
        gap: 36px;
        font-weight: 500;
        font-size: 14px;
        &:last-child {
          font-size: 16px;
          color: $primary-100;
        }
      }

      .error__message {
        font-size: 12px;
      }
    }
  }
  article {
    @include flex(center, space-between);
    padding: 16px 8px;
    border-bottom: 1px solid $gray-200;

    .title {
      flex-basis: 130px;
      font-size: 16px;
      font-weight: 700;
    }
    .weight {
      @include flex(initial, center);
      margin-left: 210px;
      padding: 4px;
      background-color: $surface-100;
      border: 1px solid $gray-300;
      border-radius: 8px;

      button {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        backdrop-filter: contrast(0.9);

        &.disabled {
          opacity: 0.3;
        }
        &:first-child {
          background: kyc-icon(-44px, -218px);
        }
        &:last-child {
          background: kyc-icon(-69px, -218px);
        }
      }
      .jack__input__container {
        input {
          border: none;
          transition: none;
          appearance: textfield;

          &::-webkit-inner-spin-button,
          &::-webkit-inner-spin-button {
            appearance: none;
          }
        }
      }
    }
    .score {
      @include flex(center, initial);
      width: 240px;
      gap: 36px;

      span:first-child {
        font-size: 14px;
        font-weight: 400;
        color: $surface-medium;
      }
      span:last-child {
        font-weight: 500;
        color: $primary-80;
      }
      div {
        @include flex(center, space-between);
        flex: 1;
      }
    }
  }

  .hide {
    visibility: hidden;
  }
  .error {
    color: $error-100 !important;
  }
}

#admin-edd-setting-ra-weight-popover-content.description {
  background: kyc-icon(-456px, -310px);
  width: 234px;
  height: 76px;
  position: absolute;
  // left: 100%;
  // top: 50%;
  transform: translate(-13%, -8%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 20px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#admin-edd-setting-ra-extra-weight-popover-content.description {
  background: kyc-icon(-392px, -422px);
  width: 306px;
  height: 96px;
  position: absolute;
  // left: 100%;
  // top: 50%;
  transform: translateX(-1%);
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 24px 16px;
  scale: 1.2;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
