.jack__popover__wrapper {
  position: fixed;
  z-index: 100;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(71, 51, 51, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.jack__popover__button {
  cursor: pointer;
}

.jack__menu__container {
  .jack__popover__button {
    cursor: pointer;
  }

  .jack__menu__wrapper {
    position: fixed;
    z-index: 100;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(71, 51, 51, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
}

.jack__modal__button {
  cursor: pointer;
}
