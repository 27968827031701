@import 'common/scss/utils.scss';

.jack__radio__group.box {
  flex-direction: row !important;

  .jack__radio__item {
    @include flex(center, center);
    width: 50%;
    height: 56px;

    &:first-child {
      .jack__radio__box {
        &.checked {
          .radio-box {
            border-width: 2px;
            border-color: $primary-80 !important;
          }
        }

        .radio-box {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }
    }

    &:last-child {
      .jack__radio__box {
        &.checked {
          .radio-box {
            border-width: 2px;
            border-color: $primary-80 !important;
          }
        }

        .radio-box {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    .jack__radio__box {
      position: relative;
      width: 100%;
      height: 100%;
      &.checked {
        .radio-box-text {
          font-weight: 500;
          color: $surface-high;
        }
      }

      .radio-box {
        width: inherit;
        height: inherit;
        background: none;
        border: 1px solid $gray-200;
      }

      .radio-box-text {
        width: fit-content;
        position: absolute;
        margin-left: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $surface-medium;
        font-size: 16px;
      }
    }
  }
  &.disabled {
    .jack__radio__item {
      .jack__radio__box {
        .radio-box {
          background: $gray-100;
          border-color: $gray-300;
          color: $surface-medium;
        }
        &.checked {
          .radio-box {
            border-color: $gray-500 !important;
            border-width: 2px;
          }
        }
      }
    }
  }
}
