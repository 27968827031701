@import 'common/scss/utils.scss';

#modal-ra-item-tree-setting {
  @mixin h4 {
    h4 {
      font-size: 14px;
      font-weight: 400;
      color: $surface-medium;
    }
  }
  .condition {
    @include h4;
    @include flex(center, initial);

    &.add__item {
      @include flex(initial, initial);
      gap: 8px;
      flex-direction: column;

      .item {
        flex-basis: initial;

        h4 {
          flex-basis: 80px;
        }
        div {
          flex: 1;
        }
      }
    }

    .item {
      @include flex(center, initial);
      flex-basis: 260px;
      gap: 24px;

      &:last-child {
        flex-grow: 1;
      }
    }
  }

  .category__container {
    margin-top: 16px;
    position: relative;
    & > div {
      @include flex(initial, initial);
      border: 1px solid $gray-300;
      border-radius: 8px;
      min-height: 212px;
      max-height: 332px;
    }
    &.disabled {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $gray-100;
        opacity: 0.5;
      }
    }

    .category {
      @include flex(initial, initial);
      flex-direction: column;
      flex: 1;
      &:not(:last-child) {
        border-right: 1px solid $gray-300;
      }

      .control {
        @include flex(center, initial);
        @include h4;
        padding: 8px 16px;
        border-bottom: 1px solid $gray-300;
        height: 40px;

        .button-group {
          margin-left: auto;

          @include flex(initial, space-between);
          gap: 8px;
          button {
            width: 28px;
            height: 28px;
            border: 1px solid $gray-300;
            border-radius: 8px;
            &:first-child {
              background: kyc-icon(-71px, -220px);
            }
            &:last-child {
              background: kyc-icon(-48px, -220px);
            }
          }
        }
      }

      .content {
        @include flex(initial, initial);
        flex-direction: column;
        padding: 8px;
        overflow: auto;

        li {
          @include flex(center, space-between);
          padding: 8px;
          border-radius: 8px;
          cursor: pointer;

          &.selected {
            background-color: $gray-100;
          }

          .jack__input__container {
            flex: 1;
            &.basic {
              input {
                height: 40px;
                padding-left: 14px;
                transition: none;
              }
            }

            &.disabled {
              input {
                height: 24px;
                padding: 0;
                color: $surface-high;
                background-color: transparent;
                border: none;
                cursor: pointer;
                text-overflow: ellipsis;
              }
            }
          }

          i.hasChild {
            width: 20px;
            height: 20px;
            background: kyc-icon(-24px, -110px);
            rotate: -90deg;
            scale: 0.8;
          }
        }
      }
    }
  }

  .modal__footer {
    @include flex(center, center);

    button {
      flex: unset;
      &.footer__cancel {
        width: 162px;
      }
      &.footer__submit {
        width: 243px;
      }
    }
  }
}
