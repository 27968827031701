@import 'common/scss/utils.scss';

.Preview {
  max-width: 508px;

  .slide-content {
    width: 100%;
    display: flex;
    position: relative;

    .prev,
    .next {
      width: 25px;
      height: 25px;
      z-index: 10;
      position: absolute;

      top: 180px;
      @include flex(center, center);
      .prev-btn,
      .next-btn {
        width: 25px;
        height: 25px;
        background: url('/assets/img/kyc_icons.svg') no-repeat 2px -115px;
        cursor: pointer;
      }
    }

    .prev {
      margin-left: 32px;
    }
    .next {
      transform: scaleX(-1);

      left: 483px;
    }

    .slide-wrapper {
      @include flex(initial, initial);
      margin-left: 32px;
      // padding-left: 32px;
      div {
        @include flex(center, center);
        padding-right: 32px;
        flex: 1 0 508px;
        width: 100%;
      }
    }
  }

  .thumbnail-list {
    @include flex(initial, space-between);
    margin-left: 32px;
    margin-top: 50px;

    & > div {
      padding: 10px 10px 5px;
      border: 1px solid transparent;
      &.active {
        border: 1px solid $gray-300;
      }
    }
  }
}
