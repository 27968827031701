@import 'common/scss/utils.scss';

#root-client-linked-account-modal + .jack__modal__background__screen {
  opacity: 0.2;
}

#linked_account__modal__wrapper {
  text-align: center;
  position: relative;

  .modal__content__form {
    height: auto;
    max-height: 592px;
    overflow-y: scroll;

    .detail {
      padding-top: 158px;
      margin-bottom: 15px;
    }
    .jack__button {
      margin: 0 auto;
      border: 1px solid $gray-200;
      padding: 8px 12px;
      width: auto;
      &.ok {
        background-color: white;
        & > div {
          color: $primary-80;
        }
      }
      &.disabled {
        & > div {
          color: $surface-disabled;
        }
      }

      &.header {
        margin-right: 0;
        margin-left: auto;
        margin-bottom: 24px;
      }
    }

    .linked_account__list {
      .linked_account__list__item {
        margin-bottom: 16px;
        padding: 22px 24px;
        @include flex(center, initial);
        border: 1px solid #d9d9d9;
        border-radius: 8px;

        &.created {
          background: $success-10;
          border: 1px solid $success-100;
          border-radius: 8px;
        }

        .date {
          font-size: 0.8125rem;
          line-height: 1.25rem;
          color: $surface-medium;
        }
        .secrets {
          margin-left: 16px;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $surface-high;
        }
        .copy {
          margin-left: auto;
          font-size: 1rem;
          line-height: 1.5rem;
          text-decoration-line: underline;
          color: $primary-80;
        }
        .jack__switch__container {
          margin-left: auto;
        }
      }
      .linked_account__label {
        padding: 10px 16px;
        margin-top: 16px;
        margin-bottom: 16px;
        background: $gray-100;
        border-radius: 8px;
        .description {
          font-size: 0.8125rem;
          line-height: 1.25rem;
          position: relative;
          @include flex(center, initial);
          margin-left: 23px;

          color: $surface-medium;
          &::before {
            content: '';
            position: absolute;
            right: calc(100% + 6px);
            background: url('/assets/img/kyc_icons.svg') no-repeat -144px -196px;
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
  .modal__footer {
    position: absolute;
    bottom: 0;
    justify-content: center;
    padding: 40px 150px;
    .jack__button {
      height: 60px;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.4375rem;
      letter-spacing: 0em;
      text-align: center;
      padding: 15px 16px;
      background: #d7d8ff;
      border-radius: 8px;
      &.cancel {
        flex: 2;
        background: $gray-100;
        div {
          color: $surface-medium;
        }
      }
      &.ok {
        flex: 3;
        background: $primary-100;
        &.disabled {
          background: $primary-20;
        }
      }
    }
  }
}
