@import 'common/scss/utils.scss';

#root-admin-page-approval-delete {
  .user-info {
    @include flex(center, center);
    .customer-id {
      color: #000;
      font-weight: 500;
    }

    .vertical-line {
      width: 1px;
      height: 16px;
      background-color: $gray-300;
      margin: 0 16px;
    }

    .name {
      color: #000;
      font-weight: 500;
    }

    .username {
      color: $surface-medium;
      margin-left: 10px;
    }
  }

  hr {
    width: 80%;
    border: none;
    height: 1px;
    background-color: $gray-200;
    margin: 40px 10% 20px;
  }

  .description {
    text-align: center;
    line-height: 26px;
  }

  .modal__footer {
    .footer__submit {
      background-color: $error-100;
    }
  }
}
