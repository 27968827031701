@import 'common/scss/utils.scss';

.admin-manage-theme-setting {
  position: relative;

  @include flex(initial, initial);
  flex-direction: column;
  gap: 24px;
  transition: 0s all 3s ease;

  .theme-title {
    @include flex(center, space-between);
    h4 {
      font-weight: 500;
      font-size: 16px;
      color: $surface-high;
    }
  }
  &.edit {
    & > div:not(.theme-title) {
      position: relative;
      @include flex(initial, initial);
    }
  }
  .use-custom-theme {
    @include flex(center, space-between);
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: 16px;
    padding-left: 24px;
    line-height: 24px;

    h4 {
      font-weight: 500;
      font-size: 16px;
      color: $surface-high;
    }

    & > div:first-of-type {
      @include flex(center, initial);
      gap: 16px;
      color: $surface-medium;
      font-size: 14px;
    }
  }

  article {
    width: 100%;
  }

  section {
    flex: 1 0 50%;
    @include flex(initial, initial);
    flex-direction: column;

    &:last-child {
      border-left: 2px solid $gray-200;
    }
  }

  #admin-manage-theme-color {
    @include flex(initial, initial);

    h4 {
      flex-basis: 241px;
      font-weight: 500;
    }

    div {
      @include flex(center, initial);
    }
  }

  padding-left: 16px;

  .theme-palette {
    h4 {
      position: relative;
      width: 85px;
      margin-top: 24px;
      margin-bottom: 8px;
      color: $surface-medium;
      font-weight: 500;
    }
    .palette-item {
      h4 {
        @include flex(center, initial);
        gap: 6px;
        width: 100%;
      }
      .info-icon {
        width: 23px;
        height: 23px;
        @include flex(center, center);
        background: url('/assets/img/kyc_icons.svg') no-repeat -192px -313px;
        &.font {
          position: absolute;
          top: -2px;
          left: 95px;
        }
      }

      .jack__radio__group {
        gap: 8px;

        .jack__radio__item {
          &:first-child {
            position: relative;
          }
          &:last-child {
            i {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  .button-group {
    @include flex(center, flex-end);
    margin-left: auto;
    gap: 8px;

    button {
      padding: 8px 12px;
      border: 1px solid $gray-200;
      border-radius: 8px;

      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
      background-color: $surface-100;

      color: $surface-medium;

      &:last-child {
        color: $primary-80;
      }

      &[disabled] {
        color: $surface-disabled;
      }
    }
  }

  .theme-controller {
    display: flex;
    width: 100%;
    flex: 1 1 50%;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    // transition: max-height 0.2s ease-out;

    &.active {
      max-height: 100vh;
      margin-top: 26px;
      overflow: hidden;
      visibility: visible;
      // transition: max-height 0.6s ease-out;
    }
    .theme_preview {
      width: 100%;
    }
  }
}

#admin-theme-setting-popover-content.description,
#admin-theme-setting-font-color-popover-content.description {
  background: url('/assets/img/kyc_icons.svg') no-repeat -383px -640px;
  width: 316px;
  height: 73px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-35%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $surface-medium;
  padding: 14px 27px 18.5px 25px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#admin-theme-setting-font-color-popover-content.description {
  background: url('/assets/img/kyc_icons.svg') no-repeat -387px -550px;
}
