@import 'common/scss/utils.scss';

@mixin h4 {
  h4 {
    font-size: 16px;
    font-weight: 500;
    color: $primary-80;
  }
}

#reviewer-review-table-expand-content-custom {
  @include flex(initial, initial);
  flex-direction: column;
  width: 100%;
  padding: 24px 56px;
  gap: 16px;

  & > article:not(:last-of-type) {
    border-bottom: 2px solid $surface-high;
  }

  ul.info-section {
    font-size: 16px;
    padding: 16px 0 0 16px;

    li {
      @include flex(center, initial);
      row-gap: 16px;
      column-gap: 8px;
      margin-bottom: 16px;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        border: 2px solid $surface-high;
        border-radius: 50%;
      }

      & > p {
        font-size: 16px;
        &.warning {
          color: $error-100;
        }
        &.disabled {
          color: $surface-disabled;
        }
      }

      .label {
        flex-shrink: 0;
        width: 140px;
        margin-right: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;

        h4 {
          // elipsis
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }

      h4 {
        color: $surface-high;
      }

      .jack__button {
        width: 110px;
        border: 1px solid $gray-200;
        border-radius: 8px;
        padding: 8px 12px;
        color: $surface-medium;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }

  .reviewer-review-edd {
    @include h4;
  }

  .reviewer-review-ra-result {
    @include h4;
    font-size: 16px;

    .info-section {
      @include flex(initial, initial);
      flex-direction: column;
      gap: 24px;
      margin-top: 16px;
    }

    .ra-result {
      @include flex(center, initial);

      h4 {
        color: $surface-high;
        width: 140px;
        margin-right: 24px;
      }

      p {
        &.low {
          color: $success-100;
        }
        &.middle {
          color: $surface-high;
        }
        &.high {
          color: $error-100;
        }
      }
    }

    .ra-result-table {
      display: grid;

      .ra-result-table-row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 140px 1fr 1.5fr 2fr;
        gap: 24px;
        text-align: center;
        padding: 0 8px;

        & > *:first-child {
          width: 160px;
          text-align: initial;

          &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            border: 2px solid $surface-high;
            border-radius: 50%;
            margin-right: 8px;
          }
        }

        h4 {
          color: $surface-high;
          @include flex(center, initial);
        }
      }

      .ra-result-table-head {
        background-color: $gray-200;

        .ra-result-table-row {
          & > *:first-child {
            &::before {
              border: none;
            }
          }
        }
      }
      .ra-result-table-body {
        display: grid;
        grid-template-rows: repeat(3, 40px);
        align-items: center;

        .ra-result-table-row {
          span:nth-of-type(1) {
            font-weight: 700;
          }
        }
      }
      .ra-result-table-footer {
        display: grid;
        grid-template-rows: repeat(1, 40px);
        align-items: center;
        border-top: 1px solid $surface-high;
        margin-bottom: 16px;

        span {
          &.low {
            color: $success-100;
          }
          &.middle {
            color: $surface-high;
          }
          &.high {
            color: $error-100;
          }
        }

        .ra-result-table-row {
          span:nth-of-type(1) {
            font-weight: 700;
          }
        }
      }
    }
  }

  .reviewer-review-survey {
    @include h4;
  }
}

.reviewer-image-section-modal {
  &.expired {
    border: 0;

    .close-btn {
      top: -30px;
      right: 2px;
    }
    i.image-expired {
      width: 640px;
      height: 400px;
      background: url('/assets/img/kyc_icons.svg') no-repeat -740px -330px;
      position: relative;
    }
  }
}
