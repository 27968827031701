@import 'common/scss/utils.scss';

#kyc-footer-terms-modal {
  .modal__header {
    @include flex(center, space-between);

    i {
      @include flex(center, center);
      width: 24px;
      height: 24px;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        background: url('/assets/img/kyc_icons.svg') no-repeat -3px -167px;
        width: 19px;
        height: 19px;
      }
    }
  }
  .modal__content__form {
    margin-bottom: 40px;

    .inner-html {
      word-break: break-all;
    }
  }
}
