@import 'common/scss/utils.scss';

#root-page-guidance-modify-policy {
  .terms-layer {
    @include flex(initial, initial);

    .content {
      width: 100%;
    }

    .utils {
      @include flex(flex-end, initial);
      flex-direction: column;
      width: 38px;

      i {
        @include flex(center, center);
        width: 30px;
        height: 30px;
        cursor: pointer;
        border: 1px solid $gray-200;
        border-radius: 8px;

        &::before {
          content: '';
          display: block;
          background: url('/assets/img/kyc_icons.svg') no-repeat;
        }

        &.remove {
          &::before {
            width: 14px;
            height: 2px;
            background-position: -54px -232px;
          }
        }

        &.add {
          &::before {
            width: 14px;
            height: 14px;
            background-position: -77px -226px;
          }
        }

        &.multiple {
          &.remove {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
          }

          &.add {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
}
