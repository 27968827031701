@import 'common/scss/utils.scss';

#kyc-find-pw-form-field {
  .customer-input {
    position: relative;
    .check {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }

  .api-check-message {
    font-size: 12px;
    color: $primary-100;
    margin-top: 10px;
    &.error {
      color: $error-100;
    }
  }

  .jack__validator__button {
    background-color: $primary-100;
    margin-top: 24px;
    margin-bottom: 30px;
  }
}
